.radio-input {
  display: none;

  &:checked + label {
    color: #15bc9b;
    background-color: #bee9e1;
  }
}

.radio-label {
  color: #cccccc;
  display: grid;
  place-items: center;
  height: 48px;
  width: 65px;
  border: 2px solid #eeeeee;
  font-size: 14px;
  font-weight: bold;
  border-radius: 3px;
  margin: 0;
  &:hover {
    cursor: pointer;
  }
}
