@use '@aui/variable.scss' as *;

.confirmBlockWithRadio {
  max-width: 30rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1.5rem 0 1rem 0;
  margin-bottom: 1.6rem;
  > .warningMessage {
    color: $error;
    font-weight: bold;
    font-size: 0.875rem;
    padding: 0.5rem 0;
  }
  > .messageBox {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 1rem 1.125rem;
    color: $main;
    font-size: 0.875rem;
    font-weight: bold;
  }
  > .radio__wrapper {
    display: flex;
  }
}

@media screen and (max-width: $size-mobile) {
  @import './confirmblockwithradio.mobile.scss';
}
