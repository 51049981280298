@use '@aui/variable.scss' as *;
@import '../../../aui/variable.scss';
.step-wrap {
  .sb-card {
    padding: 1.5rem 1rem;

    &:hover {
      cursor: pointer;
    }

    .step-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__title {
        display: flex;
        align-items: center;
      }

      &__status {
        .sb-text {
          font-family: $pretendard-sans;
        }
      }
    }
  }

  .sb-card:hover {
    border: 1px solid $P-700;
  }
}

.step--disabled {
  .sb-card:hover {
    cursor: not-allowed;
  }
}
