/********************************
** withStyle을 사용한
** legacy styles => Footer.js 안에
********************************/
@use '@aui/variable.scss' as *;
.footer {
  background: $footerBg;
  padding: 30px 20px 40px;
  width: 100%;
  &__inner {
    max-width: 1064px;
    padding: 0 20px;
    margin: auto;

    &__top {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @screen md {
        flex-direction: row;
      }

      .footer-logo {
        margin-bottom: 1rem;
      }
      .footer-logo-subtitle {
        font-size: 0.875rem;
        color: #fff;
        margin-bottom: 1rem;
      }

      .footer-service-link {
        li {
          display: inline-flex;
          margin-right: 1.5rem;

          &:hover {
            text-decoration: underline;
          }
          a {
            color: #fff;
            font-size: 1rem;
          }
        }
      }

      &-left {
        width: 100%;
        @screen md {
          width: 70%;
        }
      }

      &-right {
        width: 100%;
        margin-top: 1rem;
        @screen md {
          width: 30%;
          margin-top: 0;
        }
      }

      .footer__external-links {
        display: flex;
        justify-content: left;

        @screen md {
          justify-content: end;
        }
        a {
          display: inline-block;
          margin-left: 0.5rem;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    hr {
      margin: 1.5rem 0;
      background-color: $grayAuthor;
    }

    &__bottom {
      display: flex;
      align-items: end;
      flex-direction: column;

      @screen md {
        flex-direction: row;
      }

      &-left {
        width: 100%;
        @screen md {
          width: 70%;
        }

        .footer__terms-policy {
          margin-bottom: 1rem;
          a {
            display: inline-block;
            margin-right: 1.5rem;
            color: #fff;
            font-size: 0.875rem;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .footer__company {
          &-title {
            display: flex;
            font-weight: bold;
            font-size: 0.875rem;
            color: #fff;

            > span {
              margin-left: 1rem;
            }
          }

          &-info {
            width: 85%;
            margin-top: 10px;
            color: $K-400;
            font-size: 0.75rem;
          }
        }
      }
      &-right {
        width: 100%;
        color: $K-400;
        font-size: 0.75rem;
        margin-top: 1rem;
        text-align: left;

        @screen md {
          width: 30%;
          text-align: right;
        }
      }
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './Footer.mobile.scss';
}
