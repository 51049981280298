@use '@aui/variable.scss' as *;

/* NOTE body 16px 기준 rem사용 (2px당 0.125rem 증가)
** 8px = 0.5rem 10px = 0.625rem / 12px = 0.75rem / 14px = 0.875rem
** 16px = 1rem / 18px = 1.125rem / 20px = 1.25rem / 22px = 1.375rem
** 24px = 1.5rem / 26px = 1.625rem / 28px = 1.75rem / 30px = 1.875rem
** ANCHOR size는 parent를 얼마나 차지하고 있는지를 기준으로 한다.
** ANCHOR width는 100% 설정하고 max-width를 480px로 설정한다.
*/

.sb-button {
  font-family: 'Spoqa Han Sans', sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  background-color: #e0e0e0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  border: 0;
  // TODO need to find the outline that has set somewhere else
  outline: 0;
  transition: all 0.2s ease-in-out;
  padding: 0.5em 2em; // REVIEW changed size
  -webkit-user-select: none;
  user-select: none;
}

/****************************************
 *! Variant
 ****************************************/

.sb-button--default {
  &:hover {
    background-color: #bebebe;
  }
}

.sb-button--primary {
  color: #00fff0;
  background-color: #003546;
  &:hover {
    background-color: rgba(0, 53, 70, 0.9);
  }
}

.sb-button--secondary {
  color: #00ccc0;
  background-color: #e6faf9;
  &:hover {
    background-color: #b2f3ef;
  }
}

.sb-button--success {
  color: #fff;
  background-color: #4caf50;
  &:hover {
    background-color: #16ab39;
  }
}

.sb-button--warning {
  color: #fff;
  background-color: #ff9800;
  &:hover {
    background-color: #ec8e00;
  }
}

.sb-button--danger {
  color: #fff;
  background-color: #f44336;
  &:hover {
    background-color: #d01919;
  }
}

.sb-button--navigate {
  background: #5a7fff11;
  color: #5a7fff;
  &:hover {
    background: #5a7fff33;
    color: #5a7fff;
  }
}

/****************************************
 *! Size
 ****************************************/

.sb-button--xs {
  font-size: 0.875rem;
}

.sb-button--sm {
  font-size: 1rem;
}

.sb-button--md {
  font-size: 1.125rem;
}

.sb-button--lg {
  font-size: 1.25rem;
}

/****************************************
 *! Disabled
 ****************************************/

.sb-button:disabled {
  background: #ebebeb;
  color: #6d6d6d;
  cursor: not-allowed;
}

/****************************************
 *! Loading
 ****************************************/

.sb-button.loading {
  position: relative;
  cursor: progress;
  text-shadow: none !important;
  color: transparent !important;
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0s linear, opacity 0.1s ease;
  transition: all 0s linear, opacity 0.1s ease;
}

// spinner background
.sb-button.loading:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1em;
  height: 1em;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.15);
}

// spinner
.sb-button.loading:after {
  position: absolute;
  content: '';
  width: 1em;
  height: 1em;
  animation: button-spin 0.6s linear;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #ffffff transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  box-shadow: 0px 0px 0px 1px transparent;
}

@-webkit-keyframes button-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes button-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: $size-mobile) {
  @import './button.mobile.scss';
}
