.price-card {
  width: 100%;
  max-width: unset;

  &__img {
    margin-bottom: 1.6rem;
    & > img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
  &__info {
    margin-bottom: 3.2rem;
  }
  &__btn {
    & > button {
      &:last-child {
        margin-top: 0.8rem;
      }
    }
  }
}
