@use '../../styles/variables' as *;

/****************************************
*! Container
****************************************/

.textfield__container {
  @include pretendardFont;
  color: $input__text;
}

/****************************************
*! Input Element
****************************************/

.textfield {
  position: relative;
  width: 100%;
  text-align: left;
  display: block;
  border-radius: 3px;
  font-size: 16px;
  padding-left: 16px;
  border: 2px solid $input__border;
  box-shadow: 0 0 0px 1000px white inset; //remove Chrome basic input background
  height: 48px;

  &:focus {
    outline: none;
    border: 1px solid $text;
  }

  &::placeholder {
    font-size: 0.875rem;
    color: $placeholder;
    font-weight: bold;
  }
}

/****************************************
*! Variant
****************************************/

.textfield {
  &:disabled {
    box-shadow: 0 0 0px 1000px $input__border inset;
    -webkit-user-select: none;
    user-select: none;
    cursor: not-allowed;
  }

  &.error {
    border: 1px solid #e46448 !important;
  }

  &.success {
    border: 1px solid $success !important;
  }
}

/****************************************
*! Basic - No Animation
****************************************/

.textfield__label {
  margin-bottom: 4px;
  display: block;
  font-size: 14px;
  font-weight: bold;
  height: 23px; // to maintain the label space still when there's no label
}

// NOTE placeholder interaction이 있는 input

/****************************************
*! Animated
****************************************/
// placeholder + input wrapper
.textfield__container--animated {
  .textfield {
    //! 애니메이션 발동 시 label 이동
    // NOTE case1 - input이 focus target일 때,
    // NOTE case2 - placeholder가 보이지 않을 때 === 입력된 상태
    &:focus,
    &:not(:placeholder-shown) {
      & + .textfield__label--animated {
        // 위로 올라가며 font-size 변경
        font-size: 8px;
        transform: translate(8px, -46px);
      }
    }
  }

  .textfield__label--animated {
    color: $placeholder;
    font-size: 14px;
    font-weight: bold;
    // STUB 밀림류를 방지하기 위해 block 처리
    display: block;
    position: absolute;
    transform: translate(16px, -35px);
    // STUB label won't disturb UI
    pointer-events: none;
    transition: all 0.25s ease-in-out;
  }
  // 애니메이션 textfield는 label이 placeholder를 대신하기 때문에 숨긴다.
  .textfield::placeholder {
    color: transparent;
  }

  .textfield__message-container {
    display: flex;
    align-items: center;
  }
}

/********************************
*! Error Message Styling
*******************************/
// div for space of error span
.textfield__message-container {
  // NOTE a message container should keep the space for message output
  // NOTE you can set the height to 0 if it's necessary
  height: $messageHeight;
  // 에러 메시지 wrapper
  & > div {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-left: 4px; // indent messages
  }
  .error-message {
    color: $error;
  }
  .success-message {
    color: $success;
  }
  .common-message {
    color: $success;
    font-weight: bold;
  }
}
