@use '@aui/variable.scss' as *;
@use '@aui/variable.scss' as *;

/* NOTE 특별한 경우가 아니면 body 16px 기준 rem사용
** 10px = 0.625rem / 12px = 0.75rem / 14px = 0.875rem
** 16px = 1rem / 18px = 1.125rem / 20px = 1.25rem
** 22px = 1.375rem / 24 = 1.5rem / 28 = 1.75rem;
** NOTE size는 parent를 얼마나 차지하고 있는지를 기준으로 한다.
** NOTE width는 100% 설정하고 max-width를 설정하여 일정 크기 이상 커지지 않도록 한다.
*/

.sb-text {
  font-family: 'Gmarket Sans', sans-serif;
  color: $Y-700;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  width: 100%;
  line-height: 1;

  &--primary {
    color: $Y-700;
  }

  &--secondary {
    display: -webkit-box;
    font-weight: 300;
    max-height: 50px;
    overflow: hidden;
    vertical-align: top;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
  &--xxxs {
    font-size: 0.625rem;
  }

  &--xxs {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }

  &--xs {
    font-size: 0.875rem;
    line-height: 1;
  }
  &--sm {
    font-size: 1.125rem;
  }
  &--md {
    font-size: 1.5rem;
    line-height: 1;
  }
  &--lg {
    font-size: 1.75rem;
  }
  &--xl {
    font-size: 3rem;
  }

  &--discount,
  &--negative {
    color: #e46448;
  }
}

@media screen and (max-width: $size-mobile) {
  @import './text.mobile.scss';
}
