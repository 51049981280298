.courseCertiTable {
  min-width: 700px;
  border: 1px solid #000;
  border-collapse: collapse;
  & > thead th {
    padding: 0.5em 0;
    border-bottom: 1px solid #000;
  }

  & > tbody {
    text-align: center;

    & td {
      padding: 1em;

      .sb-textfield__wrapper label {
        display: none;
      }
    }

    .engNameField .sb-textfield__wrapper {
      display: flex;
    }
  }
}
