@use '@aui/variable.scss' as *;

.progress__wrapper {
  $width: 8.5rem;
  width: $width;
  .progress {
    &__percentage {
      text-align: left;
      font-size: 1.4rem;
      line-height: 1.21;
    }
    &__graph {
      width: $width;
    }
    &__graphinner {
      background: $aqua;
    }
  }
}
