.sb-attendance {
  width: 100%;
  .sb-title {
    margin-bottom: 2.3rem;
  }

  .sb-attendance__form {
    width: 100%;
  }

  .sb-textfield-with-button {
    width: 100%;
    .sb-textfield {
      width: 100%;
    }

    .button__wrapper {
      position: relative;
      & > button {
        transform: translate(23rem, -2.8rem);
        padding: 0.5rem 1rem;
      }
    }
  }

  .sb-textfield__wrapper {
    width: 100%;
    margin-bottom: 1.6rem;

    & + button {
      width: 100%;
      height: 4rem;
    }
  }
}
