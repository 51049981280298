@use '../../styles/variables' as *;

.container {
  width: 422px;
  color: $text;
}

.checkboxWrapper {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  padding: 16px 0;
}

.arrowWrapper {
  cursor: pointer;
}
