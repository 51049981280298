@use '@aui/variable.scss' as *;
.courseIntro {
  max-width: 675px;
  &__title {
    max-width: 70%;
    margin-bottom: 2rem;
    .sb-text--md {
      font-family: $pretendard-sans;
      line-height: 1.3;
      color: $aqua;
      opacity: 1;
    }
  }

  &__desc {
    margin: 1rem 0 2rem;
    .sb-text {
      font-family: $pretendard-sans;
      line-height: 1.66em;
      font-weight: normal;
    }
  }
}
