@use '@aui/variable.scss' as *;

/* NOTE body 16px 기준 rem사용 (2px당 0.125rem 증가)
** 8px = 0.5rem 10px = 0.625rem / 12px = 0.75rem / 14px = 0.875rem
** 16px = 1rem / 18px = 1.125rem / 20px = 1.25rem / 22px = 1.375rem
** 24px = 1.5rem / 26px = 1.625rem / 28px = 1.75rem / 30px = 1.875rem
** ANCHOR size는 parent를 얼마나 차지하고 있는지를 기준으로 한다.
** ANCHOR width는 100% 설정하고 max-width를 480px로 설정한다.
*/

.sb-textbox {
  font-family: 'Spoqa Han Sans Neo', sans-serif;
  border: 1px solid #ebebeb;
  color: rgba(0, 53, 70, 0.65);
  font-size: 0.875rem;
  line-height: 1.3;
  overflow: scroll;
  overflow-x: hidden;
  border-radius: 0.125rem;
  /********************************
  ** https://css-tricks.com/almanac/properties/s/scrollbar/
  ********************************/
  &::-webkit-scrollbar {
    // STUB addresses the background of the bar itself. It is usually covered by the other elements
    width: 0.3125rem;
  }
  &::-webkit-scrollbar-track {
    // STUB addresses the empty space "below" the progress bar
  }
  &::-webkit-scrollbar-thumb {
    // STUB addresses the draggable scrolling element that resizes depending on the size of the scrollable element
    background-color: #949a9d;
    border-radius: 15px;
    height: 3.875rem;
  }
  &::-webkit-button {
  }
}

/****************************************
*! Variant
****************************************/

.sb-textbox--primary {
  font-size: 0.875rem;
  background-color: #fff;
}

.sb-textbox--secondary {
  font-size: 0.875rem;
  background-color: #fcfcfc;
  line-height: 1.5;
  //STUB 보다 나은 테스트를 위해 일시적으로 세팅해 둔 값
  img {
    display: inline;
    // ? inline image는 vertical-align만 잡아주면 된다
    vertical-align: middle;
  }
  // TODO textbox에서 span은 strong을 적용시킵니다 - 나중에 수정 필요
  span {
    font-size: 0.875rem;
    font-weight: bold;
  }
}

/****************************************
*! Size
****************************************/

.sb-textbox--sm {
  width: 33.3%;
  max-width: 10rem;
  max-height: 4rem;
  padding: 0.365rem;
}

.sb-textbox--md {
  width: 66.6%;
  max-width: 20rem;
  max-height: 8rem;
  padding: 0.625rem;
}

.sb-textbox--lg {
  width: 100%;
  max-width: 30rem;
  max-height: 12rem;
  padding: 1.125rem;
}

.sb-textbox--fitcontent {
  overflow-y: hidden;
  max-height: unset;
  height: auto;
}

@media screen and (max-width: $size-mobile) {
  @import './textbox.mobile.scss';
}
