
.sb-login {
  width: 100%;
  height: calc(100vh - #{$header-mobile-height});

  @mixin nav-link($underline) {
    color: rgba(0, 53, 70, 0.5);
    font-size: 12px;
    line-height: 1.25;
    font-weight: bold;

    @if $underline == true {
      text-decoration: underline;
    }
  }

  .sb-textfield,
  .sb-button {
    height: 3.6rem;
  }

  .sb-button {
    margin-bottom: 1.6rem;
    font-size: 1.4rem;
  }
  &__form {
    .find-password {
      width: 100%;
      text-align: right;
      margin: 0 0 1.6rem 0;
      font-size: 1.2rem;
      > a {
        @include nav-link(false);
      }
    }
  }
  &__extra {
    justify-content: center;
    a {
      @include nav-link(true);
    }
  }
}
