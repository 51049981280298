@use '@aui/variable.scss' as *;
.detail__title-wrap {
  .detail__sub {
    display: flex;
    padding: 1.5em 0 2em;

    &-date {
    }

    &-remain {
      margin-left: 2rem;
    }

    &-date > .sb-text--xxs,
    &-remain > .sb-text--xxs {
      font-family: $pretendard-sans;
      font-weight: normal;
    }
  }
}
