@use '@aui/variable.scss' as *;

.sb-steps {
  display: flex;
  align-items: center;
  overflow: hidden;
  background: #f5f5f5;
  height: 3.6rem;
  font-size: 0.8rem;
  &__step {
    position: relative;
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: $G-400;
    border: 1px solid #ddd;
    border-left-style: none;
    &:first-child {
      border-left-style: solid;
    }
    &.active {
      background: $aqua;
      color: #ffffff;
      border-top: none;
      border-bottom: none;
      &:last-child {
        border-right-style: none;
      }
    }
  }
}
