@use '@aui/variable.scss' as *;

.sessionTimerBar {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  right: calc(100% + 0.5rem);
  transform: translateY(50%);
  bottom: 50%;
  width: 32.25rem;
  height: 5.875rem;
  background-color: $Y-500;
  padding: 0 2.625rem 0 1.5rem;
  color: #ffffff;
  border-radius: 3px;
  animation: fadein 0.25s;
  box-shadow: rgb(0 0 0 / 30%) 0px 12px 60px 5px;
  > p {
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: -0.02em;
    color: $Y-900;
    > strong {
      font-weight: 700;
    }
  }
}

.closeButton {
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.25rem;
  background-color: transparent;
  display: inline-flex;
}

.extendButton {
  font-weight: 700;
  font-size: 0.75rem;
  background-color: $Y-900;
  color: $Y-500;
  border: none;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  &:disabled {
    cursor: progress;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
