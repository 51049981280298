@use '@aui/variable.scss' as *;
.listmenu {
  background-color: #fff;
  // margin-b;
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;

  //NOTE: <ul> : items
  &__items {
    margin: 0;
    padding-left: 0;
  }

  // NOTE: <li> : item
  &__item {
    display: inline-block;
    margin-right: 1.5rem;
    color: #003546;
    opacity: 0.3;
    font-family: $pretendard-sans;
    font-size: 0.875rem;
    font-weight: bold;
    cursor: pointer;
    list-style-type: none;

    // &:last-child {
    //   margin-right: 0;
    // }
    &:hover {
      opacity: 0.7;
    }
    &--active {
      opacity: 1;
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './listmenu.mobile.scss';
}
