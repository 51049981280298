@use '@aui/variable.scss' as *;
@use '@aui/variable.scss' as *;

/* NOTE 특별한 경우가 아니면 body 16px 기준 rem사용
** 10px = 0.625rem / 12px = 0.75rem / 14px = 0.875rem
** 16px = 1rem / 18px = 1.125rem / 20px = 1.25rem
** 22px = 1.375rem / 24 = 1.5rem
** NOTE size는 parent를 얼마나 차지하고 있는지를 기준으로 한다.
** NOTE width는 100% 설정하고 max-width를 설정하여 일정 크기 이상 커지지 않도록 한다.
*/
.sb-title {
  font-family: 'Gmarket Sans', sans-serif;
  color: $Y-700;
  font-weight: bold;
  line-height: 1;
  display: block;
  width: 100%;
}

/****************************************
*! Variant
****************************************/

.sb-title--primary {
  color: $Y-700;
}

/****************************************
*! Size
****************************************/

.sb-title--sm {
  font-size: 1.125rem;
}

.sb-title--md {
  font-size: 1.5rem;
}

.sb-title--lg {
  font-size: 3rem;
}

@media screen and (max-width: $size-mobile) {
  @import './title.mobile.scss';
}
