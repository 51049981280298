.sidebar {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 1rem;
    > li {
      > h2 {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.2;
        border-bottom: 1px solid rgba(33, 36, 44, 0.16);
        padding-bottom: 0.75rem;
        color: #4f4f4f;
      }
      > a {
        padding: 0.75rem 0;
        display: block;
        box-sizing: border-box;
        cursor: pointer;
        color: #4f4f4f;
        &.active {
          font-weight: bold;
        }
      }
    }
  }
}
