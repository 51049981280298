.aui-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-style: hidden;
  border-radius: 5px;
  box-shadow: 0 0 0 1px #cdcdcd;
  text-align: left;
  color: #003546;

  .aui-thead > tr {
    border-bottom: 1px solid #cdcdcd;
    th {
      background-color: #f3f3f3;
      font-weight: normal;
      font-size: 1rem;

      &:first-child {
        border-top-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
      }
    }
  }

  & > .aui-thead > tr > th,
  & > .aui-tbody > .aui-trow > .aui-tcell {
    font-size: 1rem;
    border: none;
    padding: 0.75rem 1rem;
  }
}
