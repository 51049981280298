@use '@aui/variable.scss' as *;

$primary-color: rgba(255, 200, 94, 1);
.classroom {
  margin-top: 4rem;
  & > .sb-button--navigate {
    margin: 25px 0;
  }

  &-title {
  }

  &-section {
    margin-top: 4rem;

    .course-list {
      overflow-x: auto;
    }

    &:first-of-type {
      margin-top: 0;
    }

    & > .sb-text--xs {
      margin-bottom: 1rem;
      font-family: $pretendard-sans;
    }
  }

  .admin-section {
    display: flex;

    .admin-card {
      display: flex;
      flex-direction: column;
      width: 30%;
      background: #fcfcfc;
      border: 1px solid #ebebeb;
      border-radius: 5px;
      padding: 0 !important; // REVIEW classroomTemplate하고 findClassTemplate하고 클래스명이 바뀌어야 할 것 같습니다. 서로 클래스명을 같이 쓰고 있어 추후에 변경사항이 있을 경우 수정양이 많아질 위험이 있습니다.
      &:last-child {
        margin-left: 1rem;
      }

      a {
        flex: 1;
        display: block;
        padding: 1.9375rem 1.75rem;

        &:hover {
          background-color: $primary-color;
        }
      }
    }
  }

  &__wrapper {
    @screen md {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(282px, auto));
      gap: 24px;
    }

    > *:not(:last-child) {
      margin-right: 20px;
    }

    .sb-card.sb-card--sm {
      cursor: pointer;
      // width: 100%;
      margin: 0;
      &:hover {
        .sb-card--contents {
          background-color: $primary-color;
        }
      }
    }

    &--mobile {
      flex: 1;
      overflow: auto;

      .classroom__content--mobile {
        min-height: min-content;

        .auiCardNode--md {
          margin-right: 1rem;
        }
      }
    }
  }

  &__banner {
    &_top {
      &--desktop {
        .auiBanner_right-icon {
          top: 50% !important;
          transform: translateY(-50%) !important;
          & > img {
            @screen md {
              width: 210px;
            }
          }
        }
      }
    }
    &_bottom {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 0.8rem;
      margin-bottom: 0.8rem;

      @screen md {
        flex-direction: row;
        margin-bottom: 2rem;
      }

      .firstBanner {
        .auiBanner_right-icon {
          top: 40% !important;
          right: 20px !important;
          transform: translateY(-50%);

          @screen md {
            right: 46px !important;
          }

          img {
            width: 50px;
          }
        }
      }

      .secondBanner {
        .auiBanner_right-icon {
          right: 20px !important;
          top: 50% !important;
          transform: translateY(-50%);

          @screen md {
            right: 46px !important;
          }

          img {
            width: 116px;

            @screen md {
              width: 125px;
            }
          }
        }
      }
    }

    margin-bottom: 4rem;
  }

  & > .kdc {
    .sb-text {
      color: $P-700 !important;
    }
  }

  & > .online {
    .sb-text {
      color: $Y-700 !important;
    }

    .sb-card {
      border-color: $Y-200;

      // REVIEW: 온라인의 경우 개강전 같은 라벨이 필요한지 확인할 것(일단 색 바붜둠) = Tax

      &__label {
        background: $Y-600;
      }

      &__contents {
        border-color: $Y-200;
      }
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './classroomTemplate.mobile.scss';
}
