@use '@aui/variable.scss' as *;

.field {
  display: flex;
  justify-content: space-between;

  margin-bottom: 11px;

  &__label {
    opacity: 0.65;
  }

  &__label .sb-text,
  &__value .sb-text {
    font-family: $pretendard-sans;
    font-weight: 500;

    &--price.sb-text--sm {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './labelValueField.mobile.scss';
}
