@use '@aui/variable.scss' as *;

.sb-round-select {
  font-size: 1.4rem;
  line-height: 1.28;
  .title {
  }

  .roundSelected {
    border: 1px solid #ebebeb;
    position: relative;
    margin-bottom: 0;

    &::after {
      content: '';
      height: 100%;
      width: 0.943rem;
      right: 1.125rem;
      top: 0;
      background-position-y: center;
      transition: all 0.4s;
    }
  }

  .selectBox {
    display: flex;
    width: 30rem;
    flex-direction: column;
    .optionsContainer.active {
      max-height: 20rem;
      opacity: 1;
      overflow-y: scroll;
    }

    .optionsContainer.active + .roundSelected::after {
      transform: rotateX(180deg);
      top: 0;
    }

    .optionsContainer {
      top: -1px;
    }
  }

  .selectBox .roundOption,
  .roundSelected {
    padding: 1.4rem 1rem;
    cursor: pointer;
  }
}
