@use 'colors' as *;

// colors

$white: #ffffff;
$black: #000000;

$text: $y-700;
$text--bold: $y-500;
$text--hover: $y-600;

$text__desc: $y-400;
$text__desc--hover: $y-600;

$button__text: $y;
$button__text--hover: $y-300;

$button__background: $y-700;
$button__background--hover: $y-500;

$button__text--disabled: $k-200;
$button__background--disabed: $k-400;

$input__text: $y-700;

$input__border: $k-100;

$input__background--disabled: $k-400;

$placeholder: $k-300;

$secondary: $y; // button text
$tertiary: $y-500;

$success: $success--v1;
$error: $error--v1;

// fonts

@mixin gmarketFont {
  font-family: 'Gmarket Sans', sans-serif;
}
@mixin spoqaFont {
  font-family: 'Spoqa Han Sans', sans-serif;
}

@mixin pretendardFont {
  font-family: Pretendard, sans-serif;
}
/********************************
** Sizes
********************************/
$messageHeight: 24px;
/********************************
** Viewports
********************************/
$size-mobile: 480px;
$size-tablet: 768px;
$size-monitor: 1024px;
$size-max: 1366px;
