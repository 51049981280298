.loadingPage {
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(
    100vh - 4rem
  ); //TODO: 일단 mobile 기준에서 header만 제거하는 것으로 작성. 추후에 100vh에서 header, footer 의 height를 뺀 height로 설정필요 - 창덕
  .loader {
    max-width: 300px;
  }
  > p.message {
    font-family: theme('fontFamily.pretendard'), sans-serif;
    font-weight: bold;
  }
}
