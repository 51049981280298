/* NOTE body 16px 기준 rem사용 (2px당 0.125rem 증가)
** 8px = 0.5rem 10px = 0.625rem / 12px = 0.75rem / 14px = 0.875rem
** 16px = 1rem / 18px = 1.125rem / 20px = 1.25rem / 22px = 1.375rem
** 24px = 1.5rem / 26px = 1.625rem / 28px = 1.75rem / 30px = 1.875rem
** ANCHOR size는 parent를 얼마나 차지하고 있는지를 기준으로 한다.
** ANCHOR width는 100% 설정하고 max-width를 480px로 설정한다.
*/

// NOTE Radio는 임시로 모든 value들이 pre-configure 되어있습니다.

.payment-radio__wrapper {
  font-family: 'Spoqa Han Sans Neo', sans-serif;

  .radio__title {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    color: rgba(0, 53, 70, 0.65);
    margin-bottom: 0.5rem;
  }
  .radio__group {
    display: inline-flex;
    overflow: hidden;

    .radio__input {
      display: none;
      // NOTE for both labels
      & + .radio__label {
        border: 1px solid #ebebeb;
      }
      // NOTE first radio button
      &:first-of-type + .radio__label {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }

      & ~ .radio__label:nth-child(4) {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      // NOTE selected labels
      &:checked + .radio__label {
        color: #00ccc0;
        border: 1px solid #00ccc0;
      }
    }

    .radio__label {
      padding: 0.625rem 1.5rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: rgba(0, 53, 70, 0.3);
      user-select: none;
      // STUB 두 버튼 가운데 경계선
      &:not(:last-of-type) {
        border-right: 1px solid #ebebeb;
      }
      &:hover {
        color: #00ccc0;
      }
    }
  }
}
