@use '@aui/variable.scss' as *;

.purchaseConfirm {
  &__title {
    color: rgba(0, 53, 70, 0.65);
    margin-bottom: 1.6rem;
    .sb-title {
      margin-bottom: 1.2rem;
    }
    > strong {
      font-size: 1.4rem;
      line-height: 1.28;
    }
    > p {
      font-size: 12px;
      line-height: 1.61;
    }
  }

  &__content {
    flex-direction: column;
  }
  // content 안에 있는 내용들 image & detail
  &__image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.6rem;
  }

  // 메시지가 있는 div
  &__detail {
    width: 100%;
    .alert-message-box {
      font-size: 1.4rem;
      padding: 1.2rem;
      margin: 0;
      line-height: 1.28;
      color: #e46448;
      padding: 1rem;
    }
    .purchaseConfirm__message {
      color: rgba(0, 53, 70, 0.7);
      margin-bottom: 1.6rem;
      h1 {
        font-size: 1.5rem;
        margin: 0;
      }
      .sb-button {
        font-size: 1.4rem;
      }
    }
    .purchaseConfirm__info {
      padding: 1.7rem 1.4rem 1.4rem;
      min-height: 10rem;
      height: auto;
      // 코스 제목
      .courseTitle {
        font-size: 1.8rem;
        line-height: 1;
      }
      // 회차
      .courseRound {
        font-size: 1.2rem;
        line-height: 1.25;
        margin: 0.8rem 0 1.6rem;
      }

      // 가격 있는 div - flex
      .coursePrice--flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .priceLabel {
          font-size: 1.4rem;
          display: table-cell;
          vertical-align: middle;
        }
        .priceNumber {
          font-family: $gmarket-sans;
          font-size: 1.8rem;
          line-height: 1;
        }
      }
    }

    .sb-button {
      width: 100%;
      height: 3.6rem;
      margin-top: 1rem;
      font-size: 1.4rem;
    }
  }
}
