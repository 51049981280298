/**
* NOTE: 일단 디자인 시스템 들어오기 전까진  base 비활성화
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

// NOTE: tailwind 최상단에 있어야 함.

@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');
@import './aui/variable.scss';
@import './aui/fonts.scss';

$xxxs: 0.375rem;
$xxs: 0.625rem;
$xs: 0.75rem;
$s: 0.875rem;
$m: 1rem;
$l: 1.125rem;
$xl: 1.5rem;
$xxl: 2rem;
$xxxl: 3rem;

$gm: 'Gmarket Sans';

$primary: #5a7fff;
$secondary: #ffd747;
$error: #e46448;
$confirm: #48e0a9;

$primary-text-color: #dda73c;

$g1: #f3f3f3;
$g2: #cccccc;
$g3: #a3a3a3;
$g4: #888888;
$g5: #2c2c2c;

@mixin p {
  font-size: $m;
  line-height: 1.65;
  letter-spacing: -0.3px;
}

/*
*   NAV HEADER
*/

* {
  cursor: var(--cursor-url);
}

html {
  scroll-snap-type: y mandatory;
}

html,
body {
  position: relative;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Pretendard', sans-serif !important;
  font-weight: 500 !important;
  -webkit-font-smoothing: antialiased;
  font-size: 16px !important;
  word-break: keep-all;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Spoqa Han Sans', sans-serif;
  code {
    font-size: 1em;
    background: $G-100;
  }
}

// NOTE: reset tailwind input :focus style
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  border-color: unset;
  box-shadow: unset;
}

.aiffel-container {
  background: #fff;
  color: $black;
}

.ant-layout {
  // background: ghostwhite;
  background: #fff;
}

.kernel-restart_wrapper [data-tooltip-text]:hover {
  position: relative;
}

.kernel-restart_wrapper [data-tooltip-text]:hover:after {
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  color: #ffffff;
  font-size: 12px;
  content: attr(data-tooltip-text);
  margin-bottom: 10px;
  position: absolute;
  top: 80%;
  left: 0;
  padding: 7px 12px;
  width: auto;
  min-width: 50px;
  max-width: 300px;
  word-break: keep-all;
  z-index: 9999;
}

.notification-item .notification-message {
  word-break: keep-all;
}
.ui.message .header {
  font-family: 'Spoqa Han Sans', sans-serif !important;
}
/*
*
**/

/********************************
** Main container
********************************/
.ant-layout-content {
  min-height: 100vh;
}

/********************************
** Footer container
********************************/
.ant-layout-footer {
  background: #343537;
  color: #fff;
  font-size: 12px;
}

.footer__link {
  color: #fff;
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}

.footer__etc {
  display: flex;
  padding: 0.75em 0;
  border-top: 1px solid #ddd;
}

.footer__etc-legal {
  display: flex;
  width: 85%;
  & > div {
    &:not(.footer__etc-sns) {
      margin-left: 1.25em;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.footer__etc-copyright-developer {
  color: #fff;
  font-weight: bold;
}

.footer__etc-sns {
  display: flex;
  width: 15%;
  justify-content: flex-end;
  & > div {
    margin-right: 1em;
    &:last-child {
      margin-right: 0;
    }
  }
}

.footer__etc-sns-icon {
  font-size: 2em;
}

/* react-split */
.fullscreenContainer {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2px;
  margin: 0;
}

.fullscreenContainer {
  display: flex;
}
.splittedContainer {
  overflow: hidden;
  width: 100%;
}
.gutter.gutter-vertical,
.gutter.gutter-horizontal {
  background-color: rgba(255, 0, 255, 0.25);
  cursor: row-resize;
}

/* react-split-pane */
.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  &:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }
  &.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
    &:hover {
      border-top: 5px solid rgba(0, 0, 0, 0.5);
      border-bottom: 5px solid rgba(0, 0, 0, 0.5);
    }
  }
  &.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
    &:hover {
      border-left: 5px solid rgba(0, 0, 0, 0.5);
      border-right: 5px solid rgba(0, 0, 0, 0.5);
    }
  }
  &.disabled {
    cursor: not-allowed;
    &:hover {
      border-color: transparent;
    }
  }
}
.bp3-tooltip-indicator {
  border: none;
}

span.bp3-popover-target {
  display: flex;
}

// SECTION CONTENT SIDEBAR

.stepList {
  margin: 0;
  padding: 0;
  > li {
    list-style-type: none;
  }
}

.stepListItem {
  position: relative;
  padding: 0 0 20px 25px;
  color: #000;
  cursor: pointer;
  &.active {
    color: $primary-text-color;
  }
  &.locked {
    color: #d9d9d9;
    cursor: not-allowed;
  }
  .title {
    margin: 0;
    font-size: 14px;
  }
  .time {
    color: #c3c4c9;
    font-size: 13px;
  }

  &::before {
    content: '';
    position: absolute;
    transition: border-color 0.15s ease-in-out;
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    top: 0;
    height: 100%;
    left: 0;
    width: 1px;
  }

  &::after {
    content: '';
    position: absolute;
    transition: border-color 0.15s ease-in-out;
    background: #fff;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    box-sizing: content-box;
    top: 0;
    height: 8px;
    left: -5px;
    width: 8px;
  }

  &.active,
  &:hover {
    &::before {
      border-color: $primary-text-color;
    }
    &::after {
      border-color: $primary-text-color;
      background-color: $primary-text-color;
    }
  }

  &.lastStep {
    &::before {
      border-color: #fff;
    }

    &:hover {
      border-color: #fff;
    }
  }
}

// !SECTION

// aiffel content style start

.aiffel-content {
  &__body {
    // max-width:720px;
    margin-bottom: 120px;
    p {
    }
  }
  &__image {
    margin: 1.5em 0;
    text-align: center;
    word-break: break-all;
    a {
      display: block;
      color: $G-400 !important;
      font-size: 14px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.codeExecutionResponseShell {
  max-width: 855px;
  overflow-x: auto;

  .jp-OutputArea-executeResult {
    // display: flex;
  }

  // NOTE: pandas 데이터 테이블 스타일 추가
  .dataframe {
    font-size: 14px;

    & + p {
      font-size: inherit;
    }
    tr,
    th,
    td {
      text-align: right;
      vertical-align: middle;
      padding: 0.5em 0.5em;
      line-height: normal;
      white-space: normal;
      max-width: none;
      border: none;
    }

    thead {
      border-bottom: 1px solid black;
      vertical-align: bottom;
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background: #f5f5f5;
        }
      }
    }
  }
}

.block-detail_block {
  max-width: 900px;
}

::-webkit-scrollbar {
  width: 17px;
}
::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
  padding: 2px;
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 4px;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px 1px 1px 6px;
  min-height: 28px;
  padding: 100px 0 0;
  // box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
}
::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 4px;
}

.MJXc-display {
  margin: 4em 0 !important;
}

/* TODO: 제거 필요. markdown 화면쪽에서 발생하는 side-effect가 없는지 체크후 comment 제거 - 창덕
h2 {
  margin-top: 5em !important;
  margin-bottom: 0.5em !important;
  font-weight: 700;
  font-size: 28px !important;
  &:first-child {
    margin-top: 0 !important;
  }
}
h2 {
  margin-top: 5em !important;
  margin-bottom: 0.5em !important;
  font-weight: 700;
  font-size: 24px !important;
  &:first-child {
    margin-top: 0 !important;
  }
}
h3 {
  margin-top: 5em !important;
  margin-bottom: 0.5em !important;
  font-weight: 700;
  font-size: 18px !important;
  &:first-child {
    margin-top: 0 !important;
  }
}
h4 {
  margin-top: 5em !important;
  margin-bottom: 0.5em !important;
  font-weight: 700;
}
*/
.CodeMirror {
  pre {
    font-size: 14px !important;
  }
}
.hljs {
  // font-size: 17px;
  background: #333 !important;
  color: #bababa !important;
  margin: 0;
}
.aiffel-lms-codeArea--readonly pre,
.aiffel-lms-codeArea--readonly .hljs {
  // background: #f8f8f8 !important;
}

.hljs code {
  font-size: 15px;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-section,
.hljs-attribute,
.hljs-name,
.hljs-variable,
.hljs-params {
  color: #ff58b4 !important;
}
.hljs-title,
.hljs-section,
.hljs-selector-id,
.hljs-built_in {
  color: #ffd88f !important;
}
.hljs-tag,
.hljs-name,
.hljs-attr {
  color: #3db6ff !important;
}
.hljs-literal {
  color: #00e6ff !important;
}
.hljs-string {
  color: #de7c00 !important;
}
.hljs-selector-class {
  color: #20ffcd !important;
}
.hljs-comment {
  color: #848484;
}
hr {
  // border:.5px solid $g2;
  color: $g2;
  background: $g2;
  height: 0.5px;
  border: 0;
  background-color: $g2;
}
figcaption {
  color: $g4;
  font-size: 14px;
}

pre {
  background: #333333;
  color: #bababa;
  padding: 1em;
  font-size: 15px !important;
  overflow: auto;
  code {
    font-size: 15px;
    line-height: 1.5;
  }
}

.imp-dialog.customizable.payment-danal_tpay {
  background-color: rgba(0, 0, 0, 0.5);

  & > iframe {
    top: 50% !important;
    transform: translateY(-25%);
    &.imp-frame-mobile {
      top: 0 !important;
      transform: none;
    }
  }
}

@media (min-width: #{$size-mobile + 1px}) {
  .only-mobile {
    display: none !important;
  }
}

@media screen and (max-width: $size-mobile) {
  @import './App.mobile.scss';
}

// SECTION FORUM(toastui-editor)

.toastui-editor-contents p {
  margin-top: 0 !important;
}

// !SECTION
