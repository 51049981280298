@use '@aui/variable.scss' as *;

.sb-attendance {
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .sb-title {
    width: 100%;
    max-width: 30rem;
    margin-bottom: 4rem;
  }

  .sb-attendance__form {
    width: 100%;
  }

  .sb-textfield-with-button {
    width: 100%;
    .sb-textfield {
      width: 100%;
    }

    .button__wrapper {
      position: relative;
      & > button {
        transform: translate(24rem, -2.5rem);
        padding: 0.5rem 1rem;
      }
    }
  }

  .sb-textfield__wrapper {
    width: 100%;
    .sb-textfield {
      width: 100%;
      height: 3rem;
    }

    & + button {
      width: 100%;
      height: 4rem;
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './attendancetemplate.mobile.scss';
}
