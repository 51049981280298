.errorTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .sb-title {
    font-size: 3.5rem !important;
    margin-bottom: 2rem;
  }

  .errorMessage {
    display: flex;
    flex-direction: column;
    color: #003546;
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .sb-button {
    font-size: 1rem;
    padding: 0.625rem 3.5rem;
  }
}
