@use '@aui/variable.scss' as *;

// REVIEW pricecard로 되어있어 스타일링 적용이 안되던 문제 수정
.pricefield {
  width: 100%;
  max-width: 30rem; // 480px이 가장 현재 가장 큰 width

  hr {
    margin: 1rem 0;
    border-color: #ebebeb;
  }
}

@media screen and (max-width: $size-mobile) {
  @import './pricefield.mobile.scss';
}
