/* NOTE body 16px 기준 rem사용 (2px당 0.125rem 증가)
** 8px = 0.5rem 10px = 0.625rem / 12px = 0.75rem / 14px = 0.875rem
** 16px = 1rem / 18px = 1.125rem / 20px = 1.25rem / 22px = 1.375rem
** 24px = 1.5rem / 26px = 1.625rem / 28px = 1.75rem / 30px = 1.875rem
** ANCHOR size는 parent를 얼마나 차지하고 있는지를 기준으로 한다.
** ANCHOR width는 100% 설정하고 max-width를 480px로 설정한다.
*/

.sb-select {
  font-family: 'Spoqa Han Sans', sans-serif;
  font-size: 0.9rem;
  font-weight: bold;
  width: 100%;
  max-width: 30rem;
  color: rgba(0, 53, 70, 1);

  .title {
    margin-bottom: 0.25rem;
    font-weight: normal;
  }

  .roundSelected {
    border: 1px solid #ebebeb;
    position: relative;
    margin-bottom: 0.125rem;
    //bring bottom flexbox element top
    order: 0;

    &::after {
      content: '';
      background: url(../../assets/dropdown-arrow.svg);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      height: 100%;
      width: 0.625rem;
      right: 1.125rem;
      top: 1.125rem;

      transition: all 0.4s;
    }
  }

  .selectBox {
    display: flex;
    width: 30rem;
    flex-direction: column;
    // option-container && active 둘 다 가지고 있을 때,
    .optionsContainer.active {
      max-height: 10rem;
      opacity: 1;
      overflow-y: scroll;
    }

    .optionsContainer.active + .roundSelected::after {
      transform: rotateX(180deg);
      top: -1.125rem;
    }

    .optionsContainer {
      color: rgba(0, 53, 70, 1);
      max-height: 0;
      width: 100%;
      opacity: 0;
      transition: all 0.4s;
      overflow: hidden;

      order: 1;

      .selectOption {
        border-top: 1px solid #ebebeb;
        border-left: 1px solid #ebebeb;
        border-right: 1px solid #ebebeb;
        &:last-child {
          border-bottom: 1px solid #ebebeb;
        }
      }
    }
  }

  .selectBox .optionsContainer::-webkit-scrollbar {
  }

  .selectBox .optionsContainer::-webkit-scrollbar-thumb {
    background-color: #ebebeb;
    border-radius: 1rem;
  }

  .selectBox .selectOption,
  .roundSelected {
    padding: 12px 24px;
    cursor: pointer;
  }

  .selectBox .selectOption:hover {
    background: rgba(0, 53, 70, 1);
    color: white;
  }

  .selectBox .roundLabel {
    cursor: pointer;
  }

  .selectBox .selectOption .radio {
    display: none;
  }

  .error {
    border: 1px solid #e46448;
  }
}
