@use '@aui/variable.scss' as *;
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 90vh;
    margin-top: 120px;

    @screen md {
      margin-top: 0px;
    }
  }
  .sessionExtendFloatingButton {
    position: fixed;
    bottom: 6rem;

    // 아래 두 속성은 channeltalk 아이콘과 동일한 값.
    right: 20px;
    z-index: 10000000 !important;
  }
}

:global {
  header#header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0;
    height: 64px;
    .header-inner {
      display: flex;
      max-width: 1300px;
      margin: 0 auto;
      width: 100%;
      padding: 0 40px;
      .logo-wrap {
        width: 50%;
        display: flex;
        align-items: center;
        .logo-inner {
          & img {
            width: 95px;
          }
        }
      }
      .menu-wrap {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .animalIconWrap {
          width: 40px;
          height: 40px;
          margin-right: 1em;

          img {
            width: 100%;
          }
        }

        //NOTE: semantic-ui Dropdown.Menu direction으로 오른쪽기준 설정이 되지않아 따로 스타일 설정
        .profile-dropdown-menu {
          right: 0;
          left: auto;
        }

        .profile-inner {
          padding: 1em 3em 1em 1em;
          & h3 {
            font-family: $gmarket-sans;
            font-weight: 400;
            font-size: 16px !important;
            margin-bottom: 0 !important;
          }
          & .divider {
            margin: 0 !important;
          }
          & p {
            font-family: $pretendard-sans;
            color: #707070;
          }
        }
      }
    }
  }
}
