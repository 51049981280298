$checkbox-width: 2rem;

.sb-checkbox {
  input {
    display: none;
  }
}

/****************************************
*! Variant
****************************************/

.sb-checkbox--primary {
  [type='checkbox'] {
    & + label {
      padding-left: 3rem;
      line-height: 2rem;
      font-size: 1.4rem;
    }

    & + label::before {
      left: 0;
      width: 2rem;
      height: 2rem;
      border-radius: 0.3rem;
    }
    &:checked + label::before {
      content: '';
      left: 0;
      width: 2rem;
      height: 2rem;
    }

    &:checked + label::after {
      left: 0;
      width: 2rem;
      height: 2rem;
    }

    &:not(:checked) + label::after {
      left: 0;
      width: 2rem;
      height: 2rem;
    }
  }
}

.sb-checkbox--secondary {
  // STUB before and after cannot be used in checkbox
  [type='checkbox'] {
    opacity: 0;
    & + label {
      line-height: 1.5rem;
      padding-left: 2.5rem;
      font-size: 0.875rem;
    }

    & + label::before {
      left: 0;
      width: 2rem;
      height: 2rem;
      border-radius: 0.3rem;
    }

    &:checked + label::before {
      left: 0;
      width: 2rem;
      height: 2rem;
    }

    &:checked + label::after {
      left: 0;
      width: 2rem;
      height: 2rem;
    }

    &:not(:checked) + label::after {
      left: 0;
      width: 2rem;
      height: 2rem;
      background-size: 0.875rem;
    }
  }
}