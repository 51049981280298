.radio__wrapper {
  .radio__title {
    margin-bottom: 0.8rem;
    font-size: 1.4rem;
    line-height: 1.3;
  }

  .radio__group {
    .radio__input {
    }
    .radio__label {
      font-size: 1.4rem;
    }
  }
}
