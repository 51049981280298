.loadingLayer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10000001; // channeltalk floating button보다 높게 설정
}

body.holdScroll {
  overflow-y: hidden;
}
