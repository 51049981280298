.searchfilterfield {
  display: flex;
  justify-content: space-between;

  & .filter-wrap {
  }
  & .search-wrap {
    .sb-textfield__wrapper {
      position: relative;
      .sb-textfield {
        height: 3rem;
        border-radius: 0;
      }

      // NOTE 0916 TextFieldWithButton 버튼 위치 이상해서 임시로 여기서 수정함. (확인 - 남겨둠)
      .button__wrapper {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-10px, 7px) !important;

        button {
          transform: none;
        }
      }
    }
  }
}
