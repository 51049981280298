.confirmBlockWithRadio {
  max-width: unset;
  padding: 1.6rem 0;
  > .sb-text--sm,
  > .warningMessage,
  > .messageBox {
    font-size: 1.4rem;
    line-height: 1.28;
  }

  .warningMessage {
    padding: 0.8rem 0;
  }

  .messageBox {
    margin-bottom: 0.8rem;
  }

  .sb-checkbox--secondary [type='checkbox'] + label {
    font-size: 1.2rem;
  }
  .radio__wrapper {
    gap: 1.6rem;
    .sb-checkbox--lg {
      width: auto;
    }
  }
}
