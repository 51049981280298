@use 'sass:math';
@use '@aui/variable.scss' as *;

$bg-color: $Y-900;
$vertical-padding: 1.875rem;
$horizontal-padding: 2.5rem;
$min-height: 168px;
.subHeader {
  background-color: $bg-color;
  color: #ffffff;
  // max-height: 8rem;
  min-height: $min-height;
  display: flex;
  align-items: center;
  justify-content: start;
  .container {
    max-width: 1300px;
    width: 100%;
    height: $min-height;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $horizontal-padding;
    display: flex;
    flex-direction: row;
    .navigationAndTitle {
      flex: 1;
      align-self: center;
      > nav {
        box-sizing: border-box;
        margin-bottom: 5px;
        > span,
        > a {
          color: #ffffff;
          font-style: normal;
          font-weight: 400;
          font-size: 1rem;
          line-height: 156%;
        }
        > a:hover {
          text-decoration: underline;
        }
      }
      > h2 {
        font-family: 'Gmarket Sans';
        font-style: normal;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 165%;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        > .chip {
          display: inline-flex;
          font-style: normal;
          font-weight: 700;
          font-size: 0.75rem;
          line-height: 1;
          border: 1px solid #ffffff;
          padding: 0.5rem 0.75rem;
          border-radius: 0.875rem;
          margin-left: 1rem;
        }
      }
    }
  }
}
