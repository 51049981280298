.project-rubric {
  &-wrap {
    display: flex;

    & .project-rubric-title {
      display: flex;
      flex: 0 0 15em;

      & .project-rubric-rating {
        flex: 0 0 6em;
      }
    }

    & .divider {
      height: auto;
    }
  }
}
