@use '@aui/variable.scss' as *;

.divider {
  &--horizontal {
    margin: 1rem 0;
    width: 100%;
    height: 0;
    border-top: 1px solid #ebebeb;
  }

  &--vertical {
    margin: 0 1rem;
    width: 0;
    height: 100%;
    border-left: 1px solid #ebebeb;
  }
}

@media screen and (max-width: $size-mobile) {
  @import './divider.mobile.scss';
}
