@use '../../styles/variables' as *;

.checkbox__wrapper {
  [type='checkbox'] {
    opacity: 0;
    &:focus {
      & + label {
        color: $text--hover;
      }
    }
    & + label {
      position: relative;
      cursor: pointer;
      display: inline-block;
      padding-left: 40px;
      -webkit-user-select: none;
      user-select: none;
      font-size: 16px;
      color: $text;
      font-weight: bold;
      &:hover {
        color: $text--hover;
      }
      margin-left: -12px;
    }

    & + label::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid #ebebeb;
      border-radius: 3px;
      background: #fff;
    }
    //* background doesn't need top, left property
    &:checked + label::before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      background: $text;
    }

    &:checked + label::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      background-size: 16px;
      background-image: url(./icons/checker.svg);
      background-position: center;
      background-repeat: no-repeat;
      transform: scale(1);
      opacity: 1;
      transition: all 0.25s ease-in-out;
    }

    &:not(:checked) + label::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      background-size: 0.875rem;
      background-image: url(./icons/checker.svg);
      background-position: center;
      background-repeat: no-repeat;
      transform: scale(0);
      opacity: 0;
    }

    &:disabled + label {
      color: #cccccc;
      cursor: not-allowed;
    }
  }
}
