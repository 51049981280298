.banner {
  &-title {
    margin-top: 1rem;
    margin-left: 1.5rem;
    width: 280px;
  }

  &-btn {
    position: absolute;
    right: 1rem;
    bottom: 1.3rem;
    word-break: keep-all;

    & > a {
      text-decoration: none;
    }
    & > a:after {
      position: absolute;
      content: '';
      height: 2px;
      left: 0;
      right: 0;
      width: 130px;
      background: #003546;
    }
  }
}

.banner-bg {
  position: relative;
}

.banner-content {
  position: absolute;
  top: 0;
  left: 0;
}
