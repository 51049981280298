@use '@aui/variable.scss' as *;
.progress__wrapper {
  width: 100px;
  .progress {
    background-color: transparent;
    &--lg {
      width: 150px;
    }

    &__percentage {
      text-align: right;
      font-weight: 700;
      margin-bottom: 0.2em;
    }
    &__graph {
      width: 100px;
      height: 8px;
      background: #fff;
      border-radius: 5px;
      border: 1px solid $border-color-gray;
    }
    &__graphinner {
      width: 36%;
      background: $Y-500;
      height: 100%;
      border-radius: 5px;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './progress.mobile.scss';
}
