$k: #000000;
$k-100: #eeeeee;
$k-200: #cccccc;
$k-300: #aaaaaa;
$k-400: #888888;
$k-500: #666666;
$k-600: #444444;
$k-700: #222222;

$p: #00ff94;
$p-100: #e6f4f1;
$p-200: #bee9e1;
$p-300: #91e3d2;
$p-400: #3cddbc;
$p-500: #15bc9b;
$p-600: #07836a;
$p-700: #004436;

$y: #ffd000;
$y-100: #f4f0e6;
$y-200: #e9dbbe;
$y-300: #e3c891;
$y-400: #dda73c;
$y-500: #bc8515;
$y-600: #835a07;
$y-700: #442d00;

$m: #ff00b7;
$m-100: #f4e6f0;
$m-200: #e9bedd;
$m-300: #e391cc;
$m-400: #dd3caf;
$m-500: #bc158d;
$m-600: #830760;
$m-700: #440031;

$c: #00d1ff;
$c-100: #e6edf4;
$c-200: #bed4e9;
$c-300: #91bce3;
$c-400: #3c8fdd;
$c-500: #156bbc;
$c-600: #074783;
$c-700: #002344;

$danger--v1: #ff0000;
$error--v1: #e46448;
$success--v1: #48e0a9;
