.sb-img {
  border-radius: 5px;
  max-width: 100%;
  height: 100%;
  display: inline-block;
}

.sb-img--sm {
}
.sb-img--md {
}
.sb-img--lg {
}
