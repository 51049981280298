.modalWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  padding: 2rem;
  background: #fff;
}
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.modalTitle {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1;
}
.modalClose img {
  cursor: pointer;
}

.modalContent {
  padding: 2rem 0;
}
.modalFooter {
  text-align: center;
}
