.container {
  margin: 0 auto;
  flex: 1;
  padding: 1rem;

  @screen md {
    padding: 32px;
  }
  overflow: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
