@use '@aui/variable.scss' as *;

.find-class {
  margin-top: 4rem;
  & > .sb-button--navigate {
    margin: 25px 0;
  }

  &-title {
  }

  &-banners {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 2rem;

    @screen md {
      flex-direction: row;
    }

    .firstBanner {
      margin-bottom: 0.5rem;

      flex-grow: 1;

      .auiBanner_right-icon {
        right: 6px !important;

        @screen md {
          right: 28px !important;
        }
        & > img {
          width: 98px;

          @screen md {
            width: 165px;
          }
        }
      }

      @screen md {
        margin-bottom: 0;
      }
    }

    .secondBanner {
      .auiBanner_right-icon {
        top: 50% !important;
        right: 0.5rem !important;
        transform: translateY(-50%) !important;
        @screen md {
          right: 28px !important;
        }
        & > img {
          width: 90px;

          @screen md {
            width: 165px;
          }
        }
      }
    }
  }
  &-section {
    margin-top: 4rem;
    & > .sb-text--xs {
      margin-bottom: 1rem;
      font-family: $pretendard-sans;
    }
  }

  .admin-section {
    display: flex;
    .admin-card {
      width: 30%;
      background: #fcfcfc;
      border: 1px solid #ebebeb;
      border-radius: 5px;
      padding: 1.9375rem 1.75rem;

      &:last-child {
        margin-left: 1rem;
      }
    }
  }
  &__wrapper {
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(164px, auto));
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;
    margin-top: 24px;

    @screen md {
      grid-template-columns: repeat(auto-fill, minmax(282px, auto));
      gap: 24px;
    }

    .node-card--sm {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__banner {
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: $size-mobile) {
  @import './findClassTemplate.mobile.scss';
}
