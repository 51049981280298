.sb-title--sm {
  // TODO: 모바일 sm 사이즈 적용
}

.sb-title--md {
  font-size: 2.4rem;
}

.sb-title--lg {
  font-size: 2.4rem;
}
