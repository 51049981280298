@use '@aui/variable.scss' as *;

/* NOTE body 16px 기준 rem사용 (2px당 0.125rem 증가)
** 8px = 0.5rem 10px = 0.625rem / 12px = 0.75rem / 14px = 0.875rem
** 16px = 1rem / 18px = 1.125rem / 20px = 1.25rem / 22px = 1.375rem
** 24px = 1.5rem / 26px = 1.625rem / 28px = 1.75rem / 30px = 1.875rem
** ANCHOR size는 parent를 얼마나 차지하고 있는지를 기준으로 한다.
** ANCHOR width는 100% 설정하고 max-width를 480px로 설정한다.
*/

// REVIEW checkbox는 primary와 secondary 두 종류가 있으며, primary는 dropdown button이 있고, secondary는 pure checkbox이다.

$leftVal: -0.9rem;

.sb-checkbox {
  font-family: 'Spoqa Han Sans', sans-serif;
  display: flex;
  align-items: center;
  font-weight: bold;
  // STUB button for primary
  &__button {
    &:hover {
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
    }
  }

  .error::before {
    border: 1px solid #e46448 !important;
  }
}

/****************************************
*! Variant
****************************************/

.sb-checkbox--primary {
  justify-content: space-between;
  padding-right: 1rem;
  // STUB before and after cannot be used in checkbox
  [type='checkbox'] {
    opacity: 0;
    & + label {
      position: relative;
      cursor: pointer;
      display: inline-block;
      padding-left: 2.5rem;
      color: #666;
      line-height: 1.5rem;
      -webkit-user-select: none;
      user-select: none;
      font-size: 0.875rem;
      color: rgba(0, 53, 70, 0.65);
    }

    & + label::before {
      content: '';
      position: absolute;
      left: $leftVal;
      top: 0;
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid #ebebeb;
      border-radius: 3px;
      background: #fff;
    }

    &:checked + label::before {
      content: '';
      position: absolute;
      left: $leftVal;
      top: 0;
      width: 24px;
      height: 24px;
      background: rgb(0, 53, 70);
    }

    &:checked + label::after {
      content: '';
      position: absolute;
      left: $leftVal;
      top: 0;
      width: 24px;
      height: 24px;
      background-size: 0.875rem;
      background-image: url(../../assets/checker.svg);
      background-position: center;
      background-repeat: no-repeat;
      transform: scale(1);
      opacity: 1;
      transition: all 0.2s ease;
    }

    &:not(:checked) + label::after {
      content: '';
      position: absolute;
      left: $leftVal;
      top: 0;
      width: 24px;
      height: 24px;
      background-size: 0.875rem;
      background-image: url(../../assets/checker.svg);
      background-position: center;
      background-repeat: no-repeat;
      transform: scale(0);
      opacity: 0;
    }
  }
}

.sb-checkbox--secondary {
  // STUB before and after cannot be used in checkbox
  [type='checkbox'] {
    opacity: 0;
    & + label {
      position: relative;
      cursor: pointer;
      display: inline-block;
      padding-left: $leftVal;
      color: #666;
      line-height: 1.5rem;
      padding-left: 2.5rem;
      -webkit-user-select: none;
      user-select: none;
      font-size: 0.875rem;
      color: rgba(0, 53, 70, 0.65);
    }

    & + label::before {
      content: '';
      position: absolute;
      left: $leftVal;
      top: 0;
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid #ebebeb;
      border-radius: 3px;
      background: #fff;
    }

    &:checked + label::before {
      content: '';
      position: absolute;
      left: $leftVal;
      top: 0;
      width: 24px;
      height: 24px;
      background: rgb(0, 53, 70);
    }

    &:checked + label::after {
      content: '';
      position: absolute;
      left: $leftVal;
      top: 0;
      width: 24px;
      height: 24px;
      background-size: 0.875rem;
      background-image: url(../../assets/checker.svg);
      background-position: center;
      background-repeat: no-repeat;
      transform: scale(1);
      opacity: 1;
      transition: all 0.2s ease;
    }

    &:not(:checked) + label::after {
      content: '';
      position: absolute;
      left: $leftVal;
      top: 0;
      width: 24px;
      height: 24px;
      background-size: 0.875rem;
      background-image: url(../../assets/checker.svg);
      background-position: center;
      background-repeat: no-repeat;
      transform: scale(0);
      opacity: 0;
    }
  }
}

/****************************************
*! Size
****************************************/

.sb-checkbox--sm {
  font-size: 0.875rem;
  width: 30%;
  max-width: 12rem;
}

.sb-checkbox--md {
  font-size: 1.125rem;
  width: 60%;
  max-width: 18rem;
}

.sb-checkbox--lg {
  font-size: 1.125rem;
  width: 100%;
  max-width: 30rem;
  height: 3rem;
}

@media screen and (max-width: $size-mobile) {
  @import './checkbox.mobile.scss';
}
