.sb-text {
  &--xxs {
    font-size: 1.2rem;
    line-height: 1.17;
  }
  &--xs {
    font-size: 1.4rem;
    line-height: 1.28;
  }
  &--lg {
    font-size: 1.8rem;
  }
  &--xl {
    font-size: 2.4rem;
  }
  &--discount,
  &--negative {
    color: #00ccc0;
  }
}
