@use '@aui/variable.scss' as *;

.node-card--sm {
  width: 250px;
  .sb-card__label {
    background-color: #003546;
    color: #fff;
    position: absolute;
    top: 1rem;
    left: 1rem;
    padding: 0.5rem;
    font-size: 14px;
    font-family: $pretendard-sans;
    z-index: 1;
  }

  .sb-card__image {
    text-align: center;
    & > img {
      border: none;
      object-fit: contain;
    }
  }

  & > img {
    border: 1px solid #ebebeb;
  }
  .sb-card__contents {
    height: 160px;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    background: #fcfcfc;
    .sb-text--primary.sb-text--sm {
      font-size: 1.25rem;
      margin-bottom: 10px;
      line-height: 1.16em;
    }

    .sb-text--secondary.sb-text--xxs {
      font-family: $pretendard-sans;
      margin-top: 0.5rem;
    }

    .sb-text--secondary.sb-text--xxxs {
      font-family: $pretendard-sans;
      margin-bottom: 5px;
      font-size: 0.75rem;
      color: #7c9ea9;
    }
  }
  .sb-card__desc-wrap {
  }
  .sb-card__achieve-wrap {
    &-bottom {
      display: flex;
      align-items: flex-end;

      & .sb-card-bottom__left {
        width: 50%;
        .sb-text {
        }
      }

      & .sb-card-bottom__right {
        width: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .sb-button--xxs {
          width: 100%;
          min-width: 7rem;
        }

        button {
          width: 100%;
          min-width: 7rem;
          font-size: 12px;
          padding: 8px 0;
          max-width: 106px;
        }
      }

      & > div:last-child {
        text-align: right;
      }
    }
  }
  .sb-card__price-wrap {
    & .sb-text--secondary {
      text-decoration: line-through;
    }

    &-bottom {
      display: flex;
      align-items: center;

      & > div {
        display: inline-block;
        width: auto;
      }

      & > div:last-child {
        margin-left: 0.5rem;
      }

      .sb-text--primary.sb-text--xs {
        font-size: 1rem;
      }

      .sb-text--discount.sb-text--xxxs {
        font-family: $pretendard-sans;
        font-size: 0.75rem;
      }
    }
  }
}

.node-card--lg {
  $left-column-width: 238px;

  display: flex;
  border: 1px solid #ebebeb;
  background: #fcfcfc;
  height: 240px;
  &:hover {
    cursor: pointer;
  }

  .sb-card__image {
    flex: 0 0 $left-column-width;
    flex-direction: row;
    text-align: center;
    background-color: #ffffff;
    border-right: 1px solid #ebebeb;
    border-radius: 5px;
    box-sizing: border-box;
  }

  .sb-card__contents {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-top {
      display: flex;
      justify-content: space-between;
      padding: 1.25rem 1.56rem;
      .sb-text.sb-text--xxs {
        font-family: Spoqa Han Sans Neo;
        font-weight: bold;
        font-size: 0.75rem;
        line-height: 1.166;
        color: rgba(0, 53, 70, 0.65);
        margin-bottom: 0.5rem;
      }
      .sb-text.sb-text--md {
        font-size: 1.5;
        line-height: 1;
      }
    }

    &-bottom {
      display: flex;
      justify-content: space-between;
      padding: 1.5rem 1.5rem;
      align-items: center;
      .sb-text {
        font-family: Spoqa Han Sans Neo;
        font-weight: bold;
        color: rgba(0, 53, 70, 0.65);
      }
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './nodecard.mobile.scss';
}
