@use '@aui/variable.scss' as *;

.purchaseConfirm {
  &__title {
    margin-bottom: 4rem;
  }

  &__content {
    display: flex;
  }
  // content 안에 있는 내용들 image & detail
  &__image {
    border: 1px solid #ebebeb;
    width: 30rem;
    margin-right: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  // 메시지가 있는 div
  &__detail {
    width: 25rem;
    .alert-message-box {
      width: 100%;
      color: $error;
      font-weight: bold;
      border: 1px solid #ebebeb;
      padding: 1rem 1.5rem;
      margin: 1rem 0 0.5rem 0;
      background-color: #fcfcfc;
    }
    .purchaseConfirm__message {
      color: rgba(0, 53, 70, 0.7);
      margin-bottom: 2rem;
      h1 {
        font-size: 1.5rem;
        margin: 0;
      }

      .sb-button {
        padding: 2rem 2rem;
        font-size: 1.125rem;
      }
    }
    .purchaseConfirm__info {
      display: flex;
      flex-direction: column;
      justify-content: center; // vertically center
      color: rgba(0, 53, 70, 1);
      border: 1px solid #ebebeb;
      border-radius: 0.25rem;
      background: #fcfcfc;
      padding: 20px;
      width: 100%;
      height: 9rem;
      // 코스 제목
      .courseTitle {
        font-family: $gmarket-sans;
        font-size: 1.125rem;
        font-weight: bold;
      }
      // 회차
      .courseRound {
        font-size: 0.875rem;
        margin-bottom: 2rem;
      }

      // 가격 있는 div - flex
      .coursePrice--flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .priceLabel {
          font-size: 0.875rem;
          display: table-cell;
          vertical-align: middle;
        }
        .priceNumber {
          font-family: $gmarket-sans;
          font-size: 1.125rem;
          font-weight: 700;
          padding: 0;
        }
      }
    }

    .sb-button {
      width: 100%;
      height: 3rem;
      margin-top: 0.5rem;
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './purchaseConfirmTemplate.mobile.scss';
}
