@use '@aui/variable.scss' as *;

.sb-checkbox-with-textbox {
  width: 100%;
  max-width: 30rem;
  border-bottom: 1px solid #ccc;

  .sb-textbox {
    margin-bottom: 1rem;
  }

  .sb-checkbox--lg {
    height: 70px;
  }
}

@media screen and (max-width: $size-mobile) {
  @import './checkboxwithtextbox.mobile.scss';
}
