.sb-banner {
  width: 100%;
  height: 12rem;
  border-radius: 0.4rem;

  a {
    position: relative;
    padding: 1.9rem 1.5rem;
    overflow: hidden;
  }

  .sb-text {
    width: 16rem;
    line-height: 1.55;
    margin-bottom: 0.8rem;
    white-space: pre-line;
  }

  .sb-banner__arrow {
    position: relative;
    top: 0;
  }

  &__icon {
    position: absolute;
    height: 100%;
    &.questionMark {
      right: 0;
      bottom: 0;
      transform: none;
      > img {
        height: 100%;
      }
    }
    &.faq {
      right: 1rem;
      bottom: 2.2rem;
      height: 5rem;
      transform: none;
      > img {
        height: 100%;
      }
    }
  }
}
