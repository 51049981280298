@use '@aui/variable.scss' as *;

.sb-banner {
  border: 1px solid #b9f2ef;
  background-color: #e3f7f6;
  width: 31.5rem;
  height: 10rem;
  position: relative;
  border-radius: 5px;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;

  //TODO need hover color variation for better UX
  a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 1rem;
    text-decoration: none;
  }

  .sb-text {
    width: 50%;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }

  .sb-banner__arrow {
    position: absolute;
    top: 6rem;
  }

  &__icon {
    &.questionMark {
      transform: translate(75%, -55%);
    }

    &.faq {
      transform: translate(65%, 0%);
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './secondbanner.mobile.scss';
}
