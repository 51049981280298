$color-y-700: #442d00;
$color-y-default: #ffd000;

.completion-banner {
  display: flex;
  align-items: center;
  padding: 1.375rem 37px 1.375rem 25px;
  justify-content: space-between;
  background: #fcfcfc;
  border-radius: inherit;

  &-icon {
    flex: 0 1 4em;
  }

  &-text {
    flex: 1 0;
    font-size: 0.875rem;
  }

  &-button {
    button {
      font-size: 1rem;
      padding: 0.5rem 1.5rem;
    }
  }

  &.online {
    .completion-banner-text {
      color: $color-y-700;
    }

    .completion-banner-button {
      button {
        background-color: $color-y-700;
        color: $color-y-default;

        &:disabled {
          opacity: 0.2;
        }
      }
    }
  }
}
