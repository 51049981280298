.textfield-with-button__wrapper {
  position: relative;
  button {
    position: absolute;
    width: 77px !important; // width is set to 100% originally
    height: 30px;
    font-size: 12px;
    right: 10px;
    top: 37px;
  }
  .eye-icon {
    position: absolute;
    right: 20px;
    top: 37px;
    color: #e3c891;
  }
  // 메시지(아랫공간) 여부와 상관없이 위쪽 기준으로 위치를 잡음
  &.label-collapsed {
    button {
      right: 10px;
      top: 10px;
    }
  }
}
