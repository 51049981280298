@use '@aui/variable.scss' as *;

.round {
  &__title {
    margin: 60px 0 40px;

    & > .sb-text--md {
      font-family: $pretendard-sans;
    }
  }

  &__list {
    & > .sb-text--xs {
      font-family: $pretendard-sans;
      font-weight: normal;
      margin-bottom: 0.8rem;
    }
  }

  & > .sb-text--xs:last-child {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: $size-mobile) {
  @import './round.mobile.scss';
}
