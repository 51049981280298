@import '../../../aui/variable.scss';

.stepBadge {
  display: inline-flex;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1;
  border: 1px solid #01ed8a;
  padding: 0.5rem 0.75rem;
  border-radius: 0.875rem;
  color: #01ed8a;
  vertical-align: middle;
}
