@use '@aui/variable.scss' as *;

.recent-node {
  margin-top: 3em;

  &:hover {
    cursor: pointer;
  }

  &__title {
    margin-bottom: 1.3rem;
    .sb-text {
      font-family: $pretendard-sans;
    }
  }

  &__content {
    .sb-card {
      padding: 1.125rem;
      .content__top {
        .content__subtitle {
          .sb-text {
            font-family: $pretendard-sans;
          }
        }
      }
      .content__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
