@use "sass:math";

html {
  font-size: #{math.div(10, 3.2)}vw !important; // 1rem -> 디바이스 가로 320px 기준일때 10px
}

.ant-layout-content {
  padding: 0 1.6rem;
  min-height: calc(100vh - #{$header-mobile-height});
}

.only-desktop {
  display: none !important;
}
