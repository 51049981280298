.profileButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  &.accountIcon {
    border-style: solid;

    > svg {
      display: inline-block;
    }
  }
}

.profileMenu {
  position: absolute;
  right: 0;
  top: calc(100% + 1rem);
  background-color: #fff;
  border: 2px solid #e9dbbe;
  border-radius: 5px;
  color: #442d00;
  z-index: 1;
  min-width: 250px;
  .email {
    padding: 0.875rem;
    margin: 0;
    border-bottom: 1px solid #e9dbbe;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    a,
    li {
      text-decoration: none;
      display: block;
      padding: 0.875rem;
      font-size: 1rem;
      line-height: 1.5;
      cursor: pointer;
      &:hover {
        background-color: #f4f0e6;
      }
      color: inherit;
    }
  }
}
