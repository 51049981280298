@use '@aui/variable.scss' as *;
/****************************************
*! Variables
****************************************/
$darkblue: rgba(0, 53, 70, 1);
$darkblue30: rgba(0, 53, 70, 0.3);
$darkblue70: rgba(0, 53, 70, 0.7); // for placeholder
$success: #48e0a9;
$danger: #e46448;

$messageHeight: 1.5em;

.sb-signup {
  font-family: 'Spoqa Han Sans', sans-serif;
  width: 30rem;

  .sb-title {
    margin-bottom: 4rem;
  }

  .sb-textbox {
    margin-bottom: 3rem;
    & + .sb-textfield-with-button {
      margin-bottom: 1rem;
    }
  }

  & > .sb-textfield__wrapper {
    margin-bottom: 1rem;
  }

  .verification__wrapper {
    margin-bottom: 1rem;
  }

  .sb-textfield {
    // NOTE 모든 input 높이 강제
    height: 3rem;
    width: 100%;
  }

  .button__wrapper {
    position: relative;
    & > button {
      transform: translate(24rem, -2.5rem) !important;
      padding: 0.5rem 1rem !important;
    }
  }

  .phone__wrapper {
    width: 30rem;
    .sb-textfield__wrapper {
      margin-bottom: 1rem;
    }
  }

  // 성, 이름 flexbox 위한 wrapper
  .name__wrapper {
    display: flex;
    width: 100%;
    // NOTE 아래 정렬
    align-items: flex-end;
    margin-bottom: 0.5rem; // STUB gutter for an element in the same catetory
    .sb-textfield__wrapper {
      flex: 1;
    }
    .sb-textfield__wrapper:first-child {
      margin-right: 0.25rem;
    }
    .sb-textfield__wrapper:first-child + .sb-textfield__wrapper {
      margin-left: 0.25rem;
    }
  }

  .radio__wrapper {
    margin-bottom: 2rem; // STUB gutter for an element with a different category
  }

  .address__wrapper {
    margin-bottom: 2rem; // STUB gutter for an element with a different category
    .sb-textfield__wrapper:first-child {
      margin-bottom: 0.5rem;
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './signuptemplate.mobile.scss';
}
