.find-class {
  &-section {
    margin-top: 3.6rem;
    & > .sb-text--xs {
      margin-bottom: 1.6rem;
      color: #00ccc0;
      font-size: 1.4rem;
      line-height: 1.28;
    }
  }
  &__wrapper {
    // display: flex;
    // flex-direction: column;
    > div {
      // margin-bottom: 1.6rem;
    }
    .node-card--sm {
      width: 100%;
    }
  }
}
