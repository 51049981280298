@use '@aui/variable.scss' as *;
.list {
  &__title {
    margin: 2rem 0 1rem;
  }
  &__sublist {
    margin-left: 0.5rem;
    & > .sb-text--xs {
      font-family: $pretendard-sans;
      font-weight: normal;
      margin-bottom: 0.8rem;
    }

    & > .sb-text--xs:last-child {
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './listWithTitle.mobile.scss';
}
