.managePageLayout {
  display: flex;
  flex-direction: column;
  .pageContents {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
    width: 100%;
    > .left {
      flex: 0 0 260px;
      padding: 3.125rem 2.5rem;
    }
    > .right {
      flex: 1;
      padding: 3.125rem 2.5rem;
    }
  }
}
