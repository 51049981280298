@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  src: local('Pretendard'),
    url('https://aiffel-front-prod-asset.s3.ap-northeast-2.amazonaws.com/fonts/Pretendard/Pretendard-Light.woff2')
      format('woff2'),
    url('https://aiffel-front-prod-asset.s3.ap-northeast-2.amazonaws.com/fonts/Pretendard/Pretendard-Light.woff')
      format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  src: local('Pretendard'),
    url('https://aiffel-front-prod-asset.s3.ap-northeast-2.amazonaws.com/fonts/Pretendard/Pretendard-Regular.woff2')
      format('woff2'),
    url('https://aiffel-front-prod-asset.s3.ap-northeast-2.amazonaws.com/fonts/Pretendard/Pretendard-Regular.woff')
      format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  src: local('Pretendard'),
    url('https://aiffel-front-prod-asset.s3.ap-northeast-2.amazonaws.com/fonts/Pretendard/Pretendard-Bold.woff2')
      format('woff2'),
    url('https://aiffel-front-prod-asset.s3.ap-northeast-2.amazonaws.com/fonts/Pretendard/Pretendard-Bold.woff')
      format('woff');
}

@font-face {
  font-family: 'Motif';
  src: local('Motif'),
    url('../fonts/Motif-variableVF.ttf') format('truetype-variations');
}
