/****************************************
*! Variant
****************************************/

.sb-textbox--primary {
  font-size: 1.4rem;
}

.sb-textbox--secondary {
  font-size: 1.4rem;
  line-height: 162.5%;
  span {
    font-size: 1.4rem;
  }
}

.sb-textbox--lg {
  max-width: none;
  max-height: 14.4rem;
  padding: 2rem;
}
