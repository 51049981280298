@use '@aui/variable.scss' as *;

.node-wrap {
  .node-content {
    &__title {
      .sb-text {
        font-family: $pretendard-sans;
      }
      margin-bottom: 1.3rem;
    }

    &__nodes {
      & .step-wrap {
        margin-bottom: 10px;
      }
    }
  }
}
