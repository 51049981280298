.node-card--lg {
  flex-direction: column;
  height: 40rem;
  .sb-card__image {
    border-right: none;
    border-bottom: 1px solid #ebebeb;
    flex: 0 0 24.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sb-card__contents {
    &-bottom {
      align-items: flex-end;
    }
  }
}

.node-card--sm {
  width: 28.8rem;
  height: auto;
  .sb-card__label {
    font-size: 1.4rem;
    padding: 0.5rem 1rem;
  }
  .sb-card__contents {
    height: 15.7rem;
    padding: 2rem;
    .sb-text--secondary.sb-text--xxs {
      margin-top: 0;
      margin-bottom: 0.6rem;
      font-weight: bold;
    }
    .sb-text--primary.sb-text--sm {
      font-size: 1.8rem;
      margin-bottom: 0.8rem;
      line-height: 1;
    }
    .sb-text--secondary.sb-text--xxxs {
      margin-bottom: 0.8rem;
      font-size: 1.2rem;
    }
  }

  .sb-card__price-wrap {
    &-bottom {
      .sb-text--primary.sb-text--xs {
        font-size: 1.2rem;
      }

      .sb-text--discount.sb-text--xxxs {
        font-size: 1.2rem;
      }
    }
  }
}
