@use '@aui/variable.scss' as *;
.star {
  display: inline-block;
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  vertical-align: middle;
  margin-right: 3px;

  &:last-child {
    margin-right: 0;
  }
  svg {
    fill: $border-color-gray;
  }
  margin-left: auto;
  &--on {
    svg {
      fill: $subyellow;
    }
  }
}
