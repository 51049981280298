.listmenu {
  padding-top: 0;
  border-bottom: 1px solid #ebebeb;
  > a {
    display: inline-block;
  }
  &__item {
    font-size: 1.4rem;
    line-height: 1.28;
    padding: 0.8rem 0;
    margin-right: 1rem;
  }
}
