/********************************
*! PREFIXES
********************************/
$prefix: 'aui';

/********************************
*! LEGACY COLOR VARIABLES
* LMS에서 사용하던 색상
********************************/
$main: #003546 !default;

$white: #ffffff;
$black: #1b1c1d;
$gray: #767676;
$brown: #a5673f;
$pink: #e03997;
$purple: #a333c8;
$teal: #00b5ad;
$green: #21ba45;
$olive: #b5cc18;
$orange: #f2711c;
$red: #db2828;
$aqua: #00ccc0;
$footerBg: #343537;
$footerTxt: #f4f6fc;

$main--body: #ffffff;
$main--text: #000;
$main--progress: #f4f6fc;
$main--button: #6181f7;
$dark--body: #1e1f21;
$dark--text: #d9d9d9;
$contentHeader: #4f4f4f;

$blue: #5a7fff;
$light: #5cd8ff;
$highlight: #00fff0;
$violet: #7c3eff;

$subyellow: #ff9d0a;

// NOTE: 색상 변수 회색 계열 K vs Gray 겹침 ?

$black: #333333;
$G-100: #f1f1f1;
$G-200: #dadada;
$G-300: #cccccc;
$G-400: #a3a3a3;
$G-500: #888888;
$G-600: #2c2c2c;

$grayAuthor: #474747;

/********************************
*! 2022 NEW COLOR SCHEME
* 원선님이 이번에 소개한 색상
********************************/
/**SECTION - AUI latest **/
$K-000: #000000;
$K-700: #222222;
$K-600: #444444;
$K-500: #666666;
$K-400: #888888;
$K-300: #aaaaaa;
$K-200: #cccccc;
$K-100: #eeeeee;

$P-000: #00ff94;
$P-700: #004436;
$P-600: #07836a;
$P-500: #15bc9b;
$P-400: #3cddbc;
$P-300: #91e3d2;
$P-200: #bee9e1;
$P-100: #e6f4f1;

$Y-000: #ffd000;
$Y-900: #1a1500;
$Y-700: #442d00;
$Y-600: #835a07;
$Y-500: #ffcd05;
$Y-400: #dda73c;
$Y-300: #e3c891;
$Y-200: #e9dbbe;
$Y-100: #f4f0e6;
$Y-1000: #574700;

$M-000: #ff00b7;
$M-700: #440031;
$M-600: #830760;
$M-500: #bc158d;
$M-400: #dd3caf;
$M-300: #e391cc;
$M-200: #e9bedd;
$M-100: #f4e6f0;

$C-000: #00d1ff;
$C-700: #002344;
$C-600: #074783;
$C-500: #156bbc;
$C-400: #3c8fdd;
$C-300: #91bce3;
$C-200: #bed4e9;
$C-100: #e6edf4;

/********************************
*! OTHER COLOR VARIABLES
* 색상과 관련된 변수들
********************************/
// $text-color: #222222; // not-used
// $placeholder-color: $K-200; // not-used

$base-bg-color: #fcfcfc;

$error: #e46448;
$success: #48e0a9;

/** SECTION - AUI latest updated **/
$status-alert: #fb8d28;
$status-success: #35b31c;
$status-danger: #c80000;

$solid: #003546;
/********************************
*! SIZE
* 크기와 관련된 변수들
********************************/
$gutter: 0.5rem; //? JM: rem으로 쓰는 것이 어떤지?
$radius: 0.25rem;

/********************************
*! Padding 
* 패딩과 관련된 변수들
********************************/
$content-padding: 3.125rem 2.5rem;

/********************************
*! Fonts
* 폰트와 관련된 변수들
********************************/
$gmarket-sans: 'Gmarket Sans', sans-serif;
$spoqa-sans: 'Spoqa Han Sans', sans-serif;
$pretendard-sans: 'Pretendard', sans-serif;
$font-family: pretendard-sans;
$font-size: 16px;
$line-height-base: 1.5715;

// Border color
$border-color-base: hsl(0, 0, 85%); // base border outline a component
$border-color-split: rgba(0, 0, 0, 0.06); // split border inside a component
$border-color-inverse: white;
$border-width-base: 1px; // width of the border for a component
$border-style-base: solid; // style of a components border

$border-color-gray: #ebebeb;

$disabled-color: fade(#000, 25%);
$disabled-bg: hsl(0, 0, 96%);
$input-disabled-bg: $disabled-bg;

// Animation
$ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1);
$ease-base-in: cubic-bezier(0.9, 0, 0.3, 0.7);
$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
$ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
$ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
$ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
$ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

/********************************
** Viewports
********************************/
$size-mobile: 420px;
$size-tablet: 768px;
$size-monitor: 1024px;
$size-max: 1366px;

/********************************
** Widths
********************************/
$max: 1300px;

/********************************
** Component Sizes
********************************/
$header-mobile-height: 4rem;
$container-mobile-padding: 3.7rem 1rem 7.7rem;

:export {
  sizeMobile: $size-mobile;
}
