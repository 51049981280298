.sb-purchase-template {
  display: flex;
  flex-direction: column;

  > .sb-title {
    margin-bottom: 4.2rem;
  }
  > .purchase-item-info {
    > .image__wrapper {
      border: 1px solid #ebebeb;
      margin-bottom: 1.6rem;
      box-sizing: border-box;
      border-radius: 3px;
      img {
        display: block;
        width: 100%;
      }
    }
    > .sb-title {
      line-height: 1.25;
      margin-bottom: 1.6rem;
    }
    > .courseAbstract {
      line-height: 1.62;
    }
    > .pricefield {
      margin-bottom: 3.6rem;
      > .divider {
        border-color: #003546;
      }
      .field {
        align-items: center;
        .field__label > .sb-text--xs,
        .field__value > .sb-text--xs {
          font-size: 1.2rem;
          line-height: 1.25;
        }
      }
    }
  }
  > .sb-textbox {
    padding: 1.4rem;
    &.kdc-student-warning {
      margin-bottom: 3.2rem;
    }
  }
  > .sb-round-select {
    margin-bottom: 1.6rem;
  }
  > .sb-text--sm,
  input[id='allChecked'] + label {
    font-size: 1.4rem;
    line-height: 1.28;
  }
  .sb-checkbox.allChecked {
    margin: 0.8rem 0;
  }

  .terms-of-refund {
    margin: 4.4rem 0 0;
  }

  .kdc-card-warning {
    margin: 1.6rem 0;
  }
}
