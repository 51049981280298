@use '@aui/variable.scss' as *;

.sb-terms {
  // border: 1px solid purple;

  .sb-title {
    margin-bottom: 4rem;
  }

  .sb-checkbox-with-textbox {
  }

  .sb-checkbox--secondary label {
    font-size: 1.125rem;
  }

  .sb-checkbox--secondary {
    margin-bottom: 0.625rem;
  }

  .sb-textbox {
    line-height: 1.5;
    img {
      display: inline;
      // ? inline image는 vertical-align만 잡아주면 된다
      vertical-align: middle;
    }
    span {
      font-size: 0.875rem;
      font-weight: bold;
    }
  }

  .sb-button {
    width: 100%;
    height: 4rem;
    margin-bottom: 0.8rem;
  }
}

@media screen and (max-width: $size-mobile) {
  @import './termstemplate.mobile.scss';
}
