/****************************************
*! Wrapper
****************************************/

.sb-textfield__wrapper {
  .sb-textfield__label {
    margin-bottom: 0.4rem;
    font-size: 1.4rem;
    line-height: 1.3;
  }
  // REVIEW wrapper's display is set to block so no need for .fill to have 100% width (please delete after review)
  &.fill {
    input {
      width: 100%;
    }
  }
}

/****************************************
*! Common
****************************************/

.sb-textfield {
  border-radius: 0.3rem;
  line-height: 1;
  &::placeholder {
    font-size: inherit;
  }
}

/****************************************
*! Size
****************************************/

// NOTE: 현재까지 모바일 input 컴포넌트 다자인이 사이즈별로 나오진 않음.
.sb-textfield--xs,
.sb-textfield--sm,
.sb-textfield--md,
.sb-textfield--lg {
  font-size: 1.4rem;
}

/****************************************
*! Animated
****************************************/

.sb-textfield__wrapper {
  .sb-textfield--animated {
    padding: 1.1rem 1.2rem;
  }
}

/********************************
** Error Message Styling
*******************************/

.sb-textfield__message-container {
  height: auto;
  .sb-textfield__message--error,
  .sb-textfield__message--success {
    font-size: 0.8rem;
  }
}
