@use '@aui/variable.scss' as *;

.sb-purchase-template {
  display: flex;

  .sb-purchase--left {
    margin-right: 1rem; //gutter

    .sb-title {
      margin-bottom: 4rem; // title gutter
    }

    .sb-button {
      width: 100%;
      height: 3rem;
    }

    .sb-round-select {
      margin-bottom: 2rem;
    }
  }

  .sb-purchase--middle {
    border-left: 1px solid #003546;
    transform: translateY(5.365rem);
    height: 50rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sb-purchase--right {
    margin-left: 1rem; //gutter
    width: 25rem;
    transform: translateY(5.365rem);

    .image__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ebebeb;
      width: 100%;
      img {
        width: 21.5rem;
      }
    }

    .sb-title {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }

    .courseAbstract {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .sb-textbox,
  .sb-select {
    margin-bottom: 1rem;
  }

  .sb-textbox:nth-child(2) {
    color: #003546;
  }
  .sb-checkbox:nth-child(6) {
    margin-bottom: 0.5rem;
  }
  .sb-textbox:nth-child(7) {
    padding: 1rem 1.5rem;
  }
  .sb-textbox:nth-child(8) {
    color: #003546;
  }
}

@media (max-width: 767px) {
  .sb-purchase--middle {
    visibility: hidden !important;
  }
}

@media screen and (max-width: $size-mobile) {
  @import './purchasetemplate.mobile.scss';
}
