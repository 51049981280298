.sb-signup {
  .sb-title {
    margin-bottom: 2.3rem;
  }
  .sb-steps {
    margin-bottom: 3.2rem;
  }

  .sb-textfield {
    height: 3.6rem;
  }

  .button__wrapper {
    position: relative;
    & > button {
      transform: translate(22rem, -3.2rem) !important;
      padding: 0.5rem 1.7rem !important;
      font-size: 1rem;
      line-height: 1.3;
    }
  }
}
