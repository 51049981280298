.list {
  > details {
    > summary {
      .sb-text--sm {
        display: inline;
        font-size: 1.4rem;
      }
    }
  }
}
