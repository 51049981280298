@use '@aui/variable.scss' as *;
.curriculum {
  &__title {
    margin: 60px 0 40px;
    & > .sb-text--md {
      font-family: $pretendard-sans;
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './curriculum.mobile.scss';
}
