@use '@aui/variable.scss' as *;
.pagination-wrap {
  .pagination {
    margin: 0;
    padding: 0;
    display: inline-block;

    .page-item {
      list-style: none;
      display: inline;
      text-align: center;
      font-family: $pretendard-sans;
      font-size: 1.125rem;
      font-weight: bold;
      color: #3b474a;
      opacity: 0.3;
      &:hover {
        opacity: 0.8;
      }
      &.active {
        opacity: 1;
      }

      .page-link {
        cursor: pointer;
        padding: 6px 12px;
        display: block;
        float: left;
      }
    }
  }
}
