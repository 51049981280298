@use '../variable' as *;

$btn-name: #{$prefix}-button;

.#{$btn-name} {
  border-radius: $radius;
  border: 1px solid $border-color-gray;
  padding: 1rem 1.25rem;
  // for spinner :before and :after
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:not(:disabled) {
    cursor: pointer;
    color: $P-000;
    background-color: $P-700;
    &:hover {
      color: $P-100;
      background-color: $P-500;
    }
  }

  &:disabled {
    color: $K-100;
    background-color: $K-300;
    cursor: not-allowed;
  }
  //! on loading
  &[aria-busy='true'] {
    position: relative; // for location of the spinner's trail (:before)
    cursor: progress;
    text-shadow: none !important;
    color: transparent !important; // hide text
    opacity: 1;
    pointer-events: auto;
    -webkit-transition: all 0s linear, opacity 0.1s ease;
    transition: all 0s linear, opacity 0.1s ease;
  }

  // spinner background
  &[aria-busy='true']:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1em;
    height: 1em;
    border-radius: 500rem;
    border: 0.2em solid rgba(0, 0, 0, 0.15);
  }
  // spinner
  &[aria-busy='true']:after {
    position: absolute;
    content: '';
    width: 1em;
    height: 1em;
    animation: button-spin 0.6s linear;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #ffffff transparent transparent;
    border-style: solid;
    border-width: 0.2em;
    box-shadow: 0px 0px 0px 1px transparent;
  }

  @keyframes button-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
