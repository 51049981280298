.sessionExtendFloatingButtonWrapper {
  position: absolute;
  > button {
    border: none;
    outline: none;
    cursor: pointer;
    border-color: #aaaaaa;
    color: #aaaaaa;
    border-width: 2px;
    width: 50px;
    height: 50px;
    border-style: none;
    font-size: 1.5rem;
    padding: 0;
    > i {
      margin: 0;
    }
    &:hover,
    &.active {
      color: #333;
      border-color: #333;
    }
  }
}
