@use '@aui/variable.scss' as *;
.price-card {
  max-width: 300px;
  position: sticky;
  top: 0;

  &__img {
    margin-bottom: 1rem;

    & > img {
      border: 1px solid #ebebeb;
      height: 300px;
    }
  }

  &__btn {
    & > button {
      width: 100%;
      max-width: 100%;
    }

    & > button:last-child {
      margin-top: 0.5rem;
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './pricecard.mobile.scss';
}
