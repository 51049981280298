@use '@aui/variable.scss' as *;

/****************************************
*! Variables
****************************************/
$darkblue: rgba(0, 53, 70, 1);
$darkblue30: rgba(0, 53, 70, 0.3);
$darkblue70: rgba(0, 53, 70, 0.7); // for placeholder
$success: #48e0a9;
$danger: #e46448;

$messageHeight: 1.5em;

// 전체 wrapper
.sb-login {
  font-family: 'Spoqa Han Sans', sans-serif;
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: 100vh;
  justify-content: center;
  align-items: center;
  // Title
  .sb-title {
    margin-bottom: 4rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .sb-textfield__wrapper {
      margin-bottom: 1rem;
    }

    // keep width all elements
    .sb-textfield__wrapper,
    .sb-textfield,
    .sb-button {
      width: 100%;
    }

    .sb-textfield,
    .sb-button {
      height: 4rem;
    }

    .sb-button {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    // 에러메세지 && label div 없앰
    .animated-label__wrapper {
      font-size: 0.857rem;
    }

    .sb-textfield__message-container {
      font-size: 0.857rem;
    }
  }

  &__extra {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20%;
    .password a,
    .signup a {
      color: rgba(0, 53, 70, 0.65);
      font-weight: bold;
      text-decoration: underline;
      padding: 0 0.125rem;
      transition: text-shadow 0.3s ease-in-out;
      &:hover {
        text-shadow: 0 0 0 rgba(0, 53, 70, 0.8);
        cursor: pointer;
      }
    }
  }

  // NOTE label position and translation setting
  // placeholder + textfield wrapper
  .sb-textfield__wrapper {
    // ! PLACEHOLDER ROLE
    .sb-textfield__label--animated {
      transform: translate(0.4rem, -2.875rem) !important;
    }

    .sb-textfield--animated {
      // NOTE case1 - textfield이 focus target일 때,
      &:focus + .animated-label__wrapper .sb-textfield__label--animated,
    // NOTE case2 - placeholder가 보이지 않을 때 === 입력된 상태
    &:not(:placeholder-shown) + .animated-label__wrapper .sb-textfield__label--animated {
        // 위로 올라가며 font-size 변경
        font-size: 0.6rem !important;
        transform: translate(0rem, -4rem) !important;
      }
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './logintemplate.mobile.scss';
}
