@use '@aui/variable.scss' as *;

.link {
  &-wrap {
    position: relative;
    cursor: pointer;
  }
  &-underline {
    display: flex;
    color: $subyellow;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    font-family: $gmarket-sans;
    padding-bottom: 5px;
    border-bottom-style: solid;
    border-bottom-width: 3px;
    width: fit-content;

    & > img {
      margin-left: 0.5em;
    }

    &__line {
      position: absolute;
      background-color: $subyellow;
      width: 100%;
      height: 3px;
      bottom: -5px;
      left: 0;
    }
  }
}

@media screen and (max-width: $size-mobile) {
  @import './link.mobile.scss';
}
