$messageHeight: 1.5em;

.sb-textfield-with-button {
  .button__wrapper {
    height: 0;
    position: relative;
    .sb-button {
      // NOTE
      // REVIEW button position should be responsive according to the size of the input
      transform: translate(12em, -3.25em);
      padding: 0.25em 0.5em;
    }
  }
}
