.sb-tableRow {
  &,
  td {
    border: 1px solid black;
  }

  td {
    padding: 0.5rem 1rem;
  }
}
