.nbcbutton {
  font-weight: 700;
  background-color: theme('colors.y.900') !important;
  border-color: theme('colors.y.900');
  color: theme('colors.y.400');

  &:hover {
    border-color: theme('colors.y.200');
    color: theme('colors.y.100');
  }

  &:disabled {
    cursor: not-allowed;
    background-color: theme('colors.k.300') !important;
    border-color: theme('colors.k.300');
    color: theme('colors.k.200');
  }

  &.bordered {
    background-color: #fff !important;
    border-color: theme('colors.k.800');
    color: theme('colors.y.800');

    &:hover {
      background-color: theme('colors.y.100');
    }

    &:disabled {
      background-color: #fff !important;
      border-color: theme('colors.k.400');
      color: theme('colors.k.300');
    }
  }

  &.secondary {
    background-color: theme('colors.y.500') !important;
    color: theme('colors.y.900') !important;

    &:hover {
      background-color: theme('colors.y.200') !important;
    }
  }

  &.danger {
    background-color: theme('colors.d') !important;
    border-color: theme('colors.d');
    color: #fff !important;
  }
}
