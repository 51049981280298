@use '@aui/variable.scss' as *;
.sb-card {
  border: 1px solid $border-color-gray;
  position: relative;
  box-sizing: border-box;
  border-radius: 5px;

  &--sm {
  }

  &--lg {
  }
}
