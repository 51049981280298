.sb-terms {
  .sb-title {
    margin-bottom: 2.3rem;
  }
  .sb-steps {
    margin-bottom: 3.2rem;
  }

  .sb-checkbox--secondary label {
    font-size: 1.4rem;
  }

  .sb-checkbox--secondary {
    margin-bottom: 3rem;
  }

  .sb-textbox {
    line-height: 1.3;
    padding: 2rem 2rem;
    word-break: break-all;
    span {
      font-size: inherit;
    }
    &--primary {
      font-size: 1.3rem;
      line-height: 1.33;
    }
    &--secondary {
      // NOTE: adjust padding to not show scrollbar and place center
      padding: 1.4rem 1.8rem;
      overflow: hidden;

      font-size: 1.4rem;
      line-height: 1.625;
    }
  }

  // NOTE: 전체동의 selector
  > .sb-checkbox {
    margin: 1rem 0 3rem;
  }
}
