@use '../../styles/variables' as *;

.button {
  display: inline-flex; // for spinner
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  font-weight: bold;
  border-radius: 3px;
  background-color: $button__background;
  color: $button--text;
  outline: none;
  border-style: none; // submit button fix
  &:hover {
    color: $button__text--hover;
    background-color: $button__background--hover;
    cursor: pointer;
  }
  // disabled일 때는 따로 focus 색상을 넣지 않는다.
  &:focus:not(:disabled) {
    box-shadow: 0 0 0 2px $button__text inset;
    outline: none;
  }

  &:disabled {
    color: $button__text--disabled;
    background-color: $button__background--disabed;
    border: 2px solid $button__background--disabed; // focus일 때 수축하지 않기 위해
    cursor: not-allowed;
  }

  //! on loading
  &[aria-busy='true'] {
    position: relative; // for location of the spinner's trail (:before)
    cursor: progress;
    color: transparent !important; // hide text
    -webkit-transition: all 0s linear, opacity 0.1s ease;
    transition: all 0s linear, opacity 0.1s ease;
  }

  // spinner background
  &[aria-busy='true']:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 4px solid rgba(0, 0, 0, 0.15);
  }
  // spinner
  &[aria-busy='true']:after {
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    animation: button-spin 0.7s linear;
    animation-iteration-count: infinite;
    border-radius: 50%;
    border-color: $white transparent transparent; // left right transparent
    border-style: solid;
    border-width: 4px;
    box-shadow: 0px 0px 0px 1px transparent;
  }

  @keyframes button-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
