@use '@aui/variable.scss' as *;

/* NOTE body 16px 기준 rem사용 (2px당 0.125rem 증가)
** 8px = 0.5rem 10px = 0.625rem / 12px = 0.75rem / 14px = 0.875rem
** 16px = 1rem / 18px = 1.125rem / 20px = 1.25rem / 22px = 1.375rem
** 24px = 1.5rem / 26px = 1.625rem / 28px = 1.75rem / 30px = 1.875rem
** ANCHOR size는 parent를 얼마나 차지하고 있는지를 기준으로 한다.
** ANCHOR width는 100% 설정하고 max-width를 480px로 설정한다.
*/

/****************************************
*! Variables
****************************************/
$darkblue: rgba(0, 53, 70, 1);
$darkblue30: rgba(0, 53, 70, 0.3);
$darkblue70: rgba(0, 53, 70, 0.7); // for placeholder
$success: #48e0a9;
$danger: #e46448;

$messageHeight: 1.5rem;
/****************************************
*! Mixins
****************************************/
// REVIEW message styles share most of options (please delete after review)
// NOTE mixin for error, success messages
@mixin message($isError) {
  display: block;
  font-size: 0.875rem;
  padding-left: 0.5rem; // indent messages
  @if $isError {
    color: $danger;
  } @else {
    color: $success;
  }
  .sb-textfield__error-icon {
    display: inline; // NOTE 두 개로 정렬 해결
    vertical-align: middle;
  }
}

/****************************************
*! Wrapper
****************************************/

.sb-textfield__wrapper {
  font-family: 'Spoqa Han Sans', sans-serif;
  transition: all 0.2s ease-in-out;

  .sb-textfield__label {
    margin-bottom: 0.25rem;
    display: block;
    color: $darkblue70;
    font-size: 1rem;
    font-weight: bold;
  }
  // REVIEW wrapper's display is set to block so no need for .fill to have 100% width (please delete after review)
  &.fill {
    input {
      width: 100%;
    }
  }
}

/****************************************
*! Common
****************************************/

.sb-textfield {
  color: $darkblue;
  font-weight: bold;
  text-align: left;
  display: block;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  padding: 0.75rem 1rem; // set label, value location
  border: 1px solid #ebebeb;
  box-shadow: 0 0 0px 1000px white inset; //remove Chrome basic input background
  margin-bottom: 0.125rem;

  &:focus {
    outline: none;
    border: 1px solid $darkblue;
  }

  &::placeholder {
    font-size: 0.875rem;
    color: rgba(0, 53, 70, 0.3);
  }
}

/****************************************
*! Variant
****************************************/

.sb-textfield {
  &--default {
    border: 1px solid #ebebeb;
    background-color: #fff;
  }

  &:disabled {
    border: 1px solid #ebebeb;
    box-shadow: 0 0 0px 1000px #f5f5f5 inset;
    -webkit-user-select: none;
    user-select: none;
  }

  &--default::placeholder,
  &:disabled::placeholder {
    color: $darkblue30;
  }

  &.error {
    border: 1px solid $danger !important;
  }

  &.success {
    border: 1px solid $success !important;
  }
}

/****************************************
*! Size
****************************************/

.sb-textfield--xs {
  font-size: 0.875rem;
}

.sb-textfield--sm {
  font-size: 1rem;
}

.sb-textfield--md {
  font-size: 1.125rem;
}

.sb-textfield--lg {
  font-size: 1.25rem;
}

// NOTE placeholder interaction이 있는 textfield

/****************************************
*! Animated
****************************************/
// placeholder + textfield wrapper
.sb-textfield__wrapper {
  // 안에 element가 focus일 때,
  &:focus-within {
  }
  // ! PLACEHOLDER ROLE
  .sb-textfield__label--animated {
    color: $darkblue30;
    font-size: 0.875rem;
    font-weight: bold;
    // STUB 밀림류를 방지하기 위해 block 처리
    display: block;
    opacity: 1;
    transform: translate(0.4rem, -2.5rem);
    transition: all 0.2s ease-in-out;
    padding-left: 1.125rem;
    // STUB label won't disturb UI
    pointer-events: none;
  }

  .sb-textfield--animated {
    border: 1px solid #ebebeb;
    background-color: #fff;
    outline: none;
    transition: all 0.2s ease-in-out;
    font-weight: bold;
    padding: 1.25rem 1rem 0.75rem 1.25rem;
    // NOTE placeholder는 보이지 않는다

    &::placeholder {
      color: transparent;
    }
    &:focus {
      border: 1px solid $darkblue30;
    }
    // ! important part for animation message style handling
    & + .animated-label__wrapper {
      height: 0;
      overflow: visible;

      // NOTE message-container takes space of .animated-label__wrapper
      & + .sb-textfield__message-container {
        transition: height 0.2s ease-in-out;
        will-change: height;
        &:empty {
          height: 0;
        }
      }
    }

    // NOTE case1 - textfield이 focus target일 때,
    &:focus + .animated-label__wrapper .sb-textfield__label--animated,
    // NOTE case2 - placeholder가 보이지 않을 때 === 입력된 상태
    &:not(:placeholder-shown) + .animated-label__wrapper .sb-textfield__label--animated {
      // 위로 올라가며 font-size 변경
      font-size: 0.5rem;
      transform: translate(-0.5rem, -3.125rem);
    }
  }
}

/********************************
** Error Message Styling
*******************************/
// NOTE .sb-textfield--error is the textfield element
.sb-textfield--error {
}
// div for space of error span
.sb-textfield__message-container {
  // NOTE a message container should keep the space for message output
  // NOTE you can set the height to 0 if it's necessary
  height: $messageHeight;
  // <span> for error message
  .sb-textfield__message--error {
    @include message($isError: true);
  }

  .sb-textfield__message--success {
    @include message($isError: false);
  }
}

@media screen and (max-width: $size-mobile) {
  @import './textfield.mobile.scss';
}
