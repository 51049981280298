@use '@aui/variable.scss' as *;
.callout {
  display: flex;
  align-items: flex-start;
  border: 1px solid #ebebeb;
  font-size: 0.875rem;
  border-radius: 5px;
  padding: 1rem;
  font-family: $pretendard-sans;
  &__icon {
    margin-right: 0.5rem;
  }

  &__body {
  }
}

.callout--info {
  background-color: #fcfcfc;
}
.callout__body {
  &--info {
    color: $main;
  }
}

.callout--warning {
}

.callout--danger {
}
