.classroom {
  &-section {
    margin-top: 3.2rem;

    & > .sb-text--xs {
      color: #00ccc0;
      margin-bottom: 1.6rem;
    }
  }

  .admin-section {
    .admin-card {
      width: auto;
      .sb-text--sm {
        font-size: 1.4rem;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    margin-bottom: 3.2rem;
    grid-gap: 0;
    > * {
      margin-right: 3.8rem;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__banner {
    .see-more-banner {
      height: 15.8rem;

      background: #ffad0f;
      border-radius: 4px;
      .banner-bg {
        height: 100%;
        > img {
          display: none;
        }
        > .banner-content {
          .banner-title {
            margin-top: 2.5rem;
            margin-left: 1.5rem;
            .sb-text--md {
              width: 16rem;
              font-size: 2.4rem;
              line-height: 1;
            }
          }
        }
      }
      .banner-btn {
        bottom: 2rem;
        right: auto;
        left: 1.5rem;
        .sb-text--xs {
          font-size: 1.6rem;
        }
        > a:after {
          width: 14.5rem;
        }
      }
    }
  }

  .empty-class {
    overflow: hidden;
    .sb-card {
      height: 15rem;
      border: 1px solid #ebebeb;
      display: flex;
      align-items: flex-end;
      > img {
        display: block;
        width: 100%;
        margin-bottom: 3rem;
      }
    }
    .banner-content {
      width: 100%;
      padding: 0.8rem;
      .banner-title {
        width: auto;
        .sb-text--md {
          font-size: 1.7rem;
          line-height: 1.2;
        }
      }
    }
  }
}
